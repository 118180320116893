import { PersistedState } from "redux-persist/es/types";
import { MarkOptional } from "ts-essentials";

import { initialFilterState } from "@kraaft/shared/core/modules/filter/filterReducers";
import {
  FilterState,
  RoomFilters,
} from "@kraaft/shared/core/modules/filter/filterState";
import {
  AsyncMigrationManifest,
  migrateFrom,
  PersistedStateOfType,
} from "@kraaft/shared/core/store/storeMigrations";

type V1FilterState = {
  [poolId: string]: MarkOptional<RoomFilters, "readingStatus">;
};

export const filterMigrations: AsyncMigrationManifest = {
  1: async () => {
    return initialFilterState as unknown as PersistedState;
  },
  2: async (state) =>
    await migrateFrom<
      PersistedStateOfType<V1FilterState>,
      PersistedStateOfType<FilterState>
    >(state, (from) => ({
      _persist: from._persist,
      pools: {
        ...Object.entries(from).reduce<FilterState["pools"]>(
          (acc, [key, value]) => {
            if (key === "_persist") {
              return acc;
            }

            const filters = value as MarkOptional<RoomFilters, "readingStatus">;

            acc[key] = { readingStatus: "all", ...filters };

            return acc;
          },
          {},
        ),
      },
    })),
};
