import type { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const DEFAULT_ANIMATION_DURATION_MS = 150;

interface HideVerticalProps {
  hidden: boolean;
  animationDuration?: number;
  className?: string;
}

export const HideVertical = ({
  children,
  hidden,
  animationDuration,
  className,
}: PropsWithChildren<HideVerticalProps>) => {
  const classes = useStyles({ animationDuration });

  return (
    <div
      className={clsx(
        classes.container,
        className,
        hidden && classes.containerHidden,
      )}
    >
      <div className={classes.content}>{children}</div>
    </div>
  );
};

interface MakeStylesProps {
  animationDuration?: number;
}

const useStyles = makeStyles({
  container: {
    display: "grid",
    transition: ({ animationDuration }: MakeStylesProps) =>
      `grid-template-rows ${
        animationDuration ?? DEFAULT_ANIMATION_DURATION_MS
      }ms`,
    gridTemplateRows: "1fr",
  },
  containerHidden: {
    gridTemplateRows: "0fr",
  },
  content: {
    display: "flex",
    overflow: "hidden",
  },
});
