import { KeyOfEnum } from "@kraaft/shared/core/types";

export const Visibility = {
  MEMBER: "member",
  ALL: "all",
  SUPERADMIN: "superadmin",
} as const;
export type Visibility = KeyOfEnum<typeof Visibility>;

export const ReadingStatus = {
  ALL: "all",
  READ: "read",
  UNREAD: "unread",
} as const;
export type ReadingStatus = KeyOfEnum<typeof ReadingStatus>;

export interface RoomFilters {
  visibility: Visibility;
  readingStatus: ReadingStatus;
  statusId: string | undefined;
  labelIds: string[];
  responsibleId: string | undefined;
  showArchived: boolean;

  searchText: string | undefined;
}

export type FilterState = { pools: { [poolId: string]: RoomFilters } };
