import { useCallback, useEffect, useRef } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectTrackDataViewHome } from "@kraaft/shared/core/modules/app/analyticsSelector";
import { AnswerNpsSurveyFormSheet } from "@kraaft/shared/core/modules/surveys/components/answerNpsSurveyForm/answerNpsSurveyFormSheet";
import { useShouldDisplayNpsSurveyForm } from "@kraaft/shared/core/modules/surveys/npsSurvey/npsSurvey.hooks";
import { useShouldShowHomeEmptyState } from "@kraaft/shared/core/modules/user/homeEmptyState.hooks";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { permissions } from "@kraaft/shared/core/services/permission/permissions.provider";
import { useFullIdentifyOnce } from "@kraaft/shared/core/utils/tracking/useFullIdentifyOnce";
import {
  useTrackEventOnceWithData,
  useTrackPage,
} from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { RoomsSidebar } from "@kraaft/web/src/views/messenger/roomSidebar";

import { RoomContainer } from "./roomContainer";

import { makeMessengerStyle, styles } from "./messenger.styles";

const Messenger = () => {
  const dispatch = useDispatch();
  const { roomId } = useParams<{ roomId: string | undefined }>();
  const shouldShowEmptyState = useShouldShowHomeEmptyState();
  const npsAnchorRef = useRef(null);

  const shouldDisplayNpsSurveyForm = useShouldDisplayNpsSurveyForm();

  const {
    element: answerNpsSurveyFormSheetElement,
    open: openAnswerNpsSurveyFormSheet,
  } = AnswerNpsSurveyFormSheet.use({});

  const [
    isConversationCreationPanelOpen,
    openConversationCreationPanel,
    closeConversationCreationPanel,
  ] = useBooleanState();

  const trackDataViewHome = useSelector(selectTrackDataViewHome);

  useTrackPage("Home");
  useTrackEventOnceWithData(trackDataViewHome);
  useFullIdentifyOnce();

  useEffect(() => {
    permissions.request("notification").catch(console.error);
  }, []);

  const classes = makeMessengerStyle();

  const closeAndAcknowledgeEmptyState = useCallback(() => {
    dispatch(UserActions.acknowledgeHomeEmptyState());

    closeConversationCreationPanel();
  }, [closeConversationCreationPanel, dispatch]);

  useEffect(() => {
    if (shouldDisplayNpsSurveyForm) {
      openAnswerNpsSurveyFormSheet({ anchor: npsAnchorRef });
    }
  }, [shouldDisplayNpsSurveyForm, openAnswerNpsSurveyFormSheet]);

  return (
    <View style={styles.main}>
      <RoomsSidebar
        isConversationCreationPanelOpen={isConversationCreationPanelOpen}
        openConversationCreationPanel={openConversationCreationPanel}
        closeConversationCreationPanel={closeAndAcknowledgeEmptyState}
      />
      {shouldShowEmptyState ? null : (
        <div id="room-container" className={classes.conversations}>
          {roomId ? <RoomContainer roomId={roomId} /> : null}
        </div>
      )}
      <View style={styles.npsAnchor} ref={npsAnchorRef} />
      {answerNpsSurveyFormSheetElement}
    </View>
  );
};

export { Messenger };
