import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { View } from "react-native";

import { UseNavigateMentionsProps } from "@kraaft/shared/components/textInputWithMentions/useNavigateMentions.props";
import {
  useCallbackRealtime,
  useEffectRealtime,
} from "@kraaft/shared/core/utils/hooks";
import { executeAfterStatePropagation } from "@kraaft/shared/core/utils/promiseUtils";

export function useNavigateMentions({
  inputFocused,
  mentions,
  handle,
  insertMention,
}: UseNavigateMentionsProps) {
  const [cursorInMentionList, setCursorInMentionList] = useState(false);
  const mentionRefs = useRef<(View | null)[]>([]);

  const [focusedMentionIndex, setFocusedMentionIndex] = useState(0);
  const [movedMouse, setMovedMouse] = useState(false);
  const show = inputFocused || cursorInMentionList;

  const move = useCallbackRealtime(
    ([_mentions], direction: "up" | "down") => {
      if (!_mentions) {
        return;
      }
      setMovedMouse(false);
      if (direction === "up") {
        setFocusedMentionIndex((oldFocused) =>
          oldFocused === 0 ? _mentions.length - 1 : oldFocused - 1,
        );
      } else if (direction === "down") {
        setFocusedMentionIndex(
          (oldFocused) => (oldFocused + 1) % _mentions.length,
        );
      }
    },
    [],
    [mentions],
  );

  useImperativeHandle(
    handle,
    useCallbackRealtime(
      ([_mentions]) => ({
        handlesKey: (key: string, shiftKey: boolean) => {
          if (!_mentions || _mentions?.length === 0) {
            return false;
          }
          if (key === "Enter") {
            const focusedMention = _mentions?.[focusedMentionIndex];
            if (!focusedMention) {
              return false;
            }
            insertMention(focusedMention);
            return true;
          }
          if (key === "ArrowUp") {
            move("up");
            return true;
          }
          if (key === "ArrowDown") {
            move("down");
            return true;
          }
          if (key === "Tab") {
            move(shiftKey ? "down" : "up");
            return true;
          }
          return false;
        },
      }),
      [focusedMentionIndex, insertMention, move],
      [mentions],
    ),
  );

  useEffect(() => {
    executeAfterStatePropagation(() => {
      setFocusedMentionIndex(0);
    });
  }, [mentions]);

  useEffectRealtime(
    ([_movedMouse]) => {
      if (movedMouse) {
        return;
      }
      (
        mentionRefs.current[focusedMentionIndex] as HTMLDivElement | undefined
      )?.scrollIntoView({ behavior: "instant" });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [focusedMentionIndex],
    [movedMouse],
  );

  useEffect(() => {
    function listenMouse() {
      setMovedMouse(true);
    }

    window.addEventListener("mousemove", listenMouse);
    return () => {
      window.removeEventListener("mousemove", listenMouse);
    };
  }, [move]);

  const onMentionHovered = useCallback(
    (index: number) => {
      if (!movedMouse) {
        return;
      }
      setFocusedMentionIndex(index);
    },
    [movedMouse],
  );

  return {
    show,
    onMentionHovered,
    onCursorInPaper: setCursorInMentionList,
    hoveredIndex: focusedMentionIndex,
    userMentionRef: (index: number) => (node: View | null) => {
      mentionRefs.current[index] = node;
    },
  };
}
