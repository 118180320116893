import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { dismissNativeKeyboard } from "@kraaft/helper-functions";
import { useMeshContext } from "@kraaft/helper-hooks";
import { NoteStep } from "@kraaft/shared/core/modules/surveys/components/answerNpsSurveyForm/answerNpsSurveyFlow/steps/noteStep";
import { TextSuggestionInput } from "@kraaft/shared/core/modules/surveys/components/answerNpsSurveyForm/answerNpsSurveyFlow/steps/textSuggestionInput";
import { ThankingStep } from "@kraaft/shared/core/modules/surveys/components/answerNpsSurveyForm/answerNpsSurveyFlow/steps/thankingStep";
import { AnswerNpsSurveyFormContext } from "@kraaft/shared/core/modules/surveys/components/answerNpsSurveyForm/answerNpsSurveyForm.context";
import { NpsSurveyActions } from "@kraaft/shared/core/modules/surveys/npsSurvey/npsSurvey.actions";
import { ButtonProps } from "@kraaft/ui";

interface AnswerNpsSurveyFlowStep {
  stepContent: React.ReactNode;
  nextButton?: ButtonProps;
  hideCancelButton?: boolean;
}

interface useAnswerNpsSurveyFlowProps {
  closeHost: () => void;
  sheetHeight: number;
}

export function useAnswerNpsSurveyFlow({
  closeHost,
  sheetHeight,
}: useAnswerNpsSurveyFlowProps): {
  stepId: keyof typeof steps;
  stepElements: AnswerNpsSurveyFlowStep;
} {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentStepId, setCurrentStepId] =
    useState<keyof typeof steps>("note");

  const {
    note,
    isSubmitNoteDisabled,
    target,
    setTarget,
    isSubmitTargetDisabled,
    benefit,
    setBenefit,
    isSubmitBenefitDisabled,
    missingFunctionality,
    setMissingFunctionality,
    isSubmitMissingFunctionalityDisabled,
  } = useMeshContext(AnswerNpsSurveyFormContext);

  const handleCreateNpsSurveyResponse = useCallback(() => {
    if (isSubmitNoteDisabled || note === null) {
      return;
    }
    dismissNativeKeyboard();
    dispatch(
      NpsSurveyActions.createNpsSurveyResponse({
        note,
      }),
    );

    setCurrentStepId("target");
  }, [dispatch, note, isSubmitNoteDisabled]);

  const handleUpdateNpsSurveyResponse = useCallback(() => {
    dismissNativeKeyboard();
    dispatch(
      NpsSurveyActions.updateNpsSurveyResponse({
        target,
        benefit,
        missingFunctionality,
      }),
    );
  }, [dispatch, target, benefit, missingFunctionality]);

  const steps = useMemo(
    () =>
      ({
        note: {
          stepContent: <NoteStep />,
          nextButton: {
            text: t("next"),
            accessibilityLabel: t("next"),
            disabled: isSubmitNoteDisabled,
            onPress: () => handleCreateNpsSurveyResponse(),
          },
        },
        target: {
          stepContent: (
            <TextSuggestionInput
              key="target-input"
              title={t("nps.form.target.title")}
              subtitle={t("nps.form.target.subtitle")}
              onChange={setTarget}
            />
          ),
          nextButton: {
            text: t("next"),
            accessibilityLabel: t("next"),
            disabled: isSubmitTargetDisabled,
            onPress: () => {
              handleUpdateNpsSurveyResponse();
              setCurrentStepId("benefit");
            },
          },
        },
        benefit: {
          stepContent: (
            <TextSuggestionInput
              key="benefit-input"
              title={t("nps.form.benefit.title")}
              onChange={setBenefit}
            />
          ),
          nextButton: {
            text: t("next"),
            accessibilityLabel: t("next"),
            disabled: isSubmitBenefitDisabled,
            onPress: () => {
              handleUpdateNpsSurveyResponse();
              setCurrentStepId("missing-functionality");
            },
          },
        },
        "missing-functionality": {
          stepContent: (
            <TextSuggestionInput
              key="missing-functionality-input"
              title={t("nps.form.missingFunctionality.title")}
              onChange={setMissingFunctionality}
            />
          ),
          nextButton: {
            text: t("finish"),
            accessibilityLabel: t("finish"),
            disabled: isSubmitMissingFunctionalityDisabled,
            onPress: () => {
              handleUpdateNpsSurveyResponse();
              setCurrentStepId("thanking");
            },
          },
        },
        thanking: {
          stepContent: (
            <ThankingStep closeHost={closeHost} height={sheetHeight} />
          ),
        },
      }) as const satisfies Record<string, AnswerNpsSurveyFlowStep>,
    [
      t,
      isSubmitNoteDisabled,
      isSubmitTargetDisabled,
      isSubmitBenefitDisabled,
      isSubmitMissingFunctionalityDisabled,
      handleUpdateNpsSurveyResponse,
      handleCreateNpsSurveyResponse,
      setBenefit,
      setMissingFunctionality,
      setTarget,
      closeHost,
      sheetHeight,
    ],
  );

  return useMemo(
    () => ({
      stepId: currentStepId,
      stepElements: steps[currentStepId],
    }),
    [currentStepId, steps],
  );
}
