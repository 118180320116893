import { StyleSheet } from "react-native";

import { Color, ColorStyle, Radius } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    borderStyle: "solid",
    borderRadius: Radius.SMALL,
    alignItems: "center",
    justifyContent: "center",

    borderWidth: 2,
    borderColor: ColorStyle.ACTION_CTA_DISABLED,
  },
  containerWithText: {
    backgroundColor: Color.WHITE,
  },
  activeBorder: {
    borderWidth: 3,
    borderColor: ColorStyle.ACTION_CTA,
  },
  rejectedBorder: {
    borderColor: Color.RED_TOMATO,
  },
});
