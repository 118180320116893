import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogContentText } from "@mui/material";
import clsx from "clsx";

import { DetailedActionCard } from "@kraaft/shared/components/detailedActionCard";
import { useInviteCollaboratorsTrackingEvent } from "@kraaft/shared/components/inviteCollaborators/useInviteCollaboratorsTrackingEvent";
import { KDialog } from "@kraaft/shared/components/kDialog";
import {
  KDialogConfig,
  KDialogSize,
} from "@kraaft/shared/components/kDialog/kDialogProps";
import { InviteType } from "@kraaft/shared/components/memberList/inviteMemberFlow";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { RoomInvitationSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";
import { useTrackEventOnceConditionnally } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { getInviteLinkForTarget } from "@kraaft/shared/core/utils/useInviteLinkMessageConfigGenerator/useInviteLinkMessageConfigGenerator.utils";
import { Preloader } from "@kraaft/ui";
import { InviteLinkInput } from "@kraaft/web/src/components/inviteLinkInput";
import { makeDialogStyle } from "@kraaft/web/src/components/kDialog/customs/inviteDialogLayout/inviteDialogLayout.styles";

type InviteInternalOrExternalCollaboratorDialogProps = {
  open: boolean;
  onClose: () => void;
  pool: Pool;
  room: Room;
  senderUserId: string;
  source: RoomInvitationSource;
  inviteType?: InviteType;
};

const InviteInternalOrExternalCollaboratorDialog = ({
  open,
  onClose,
  pool,
  room,
  senderUserId,
  source,
  inviteType,
}: InviteInternalOrExternalCollaboratorDialogProps) => {
  const { t } = useTranslation();
  const classes = makeDialogStyle();

  const [selectedInviteMethod, setInviteMethod] = useState<
    "internal" | "external" | undefined
  >(inviteType);

  const trackingEvent = useInviteCollaboratorsTrackingEvent(
    "copy_link",
    selectedInviteMethod === "internal"
      ? {
          type: "room",
          roomId: room.id,
          source,
        }
      : // selectedInviteMethod === 'external'
        {
          type: "room-external",
          roomId: room.id,
          senderUserId,
          source,
        },
  );

  useTrackEventOnceConditionnally(trackingEvent, Boolean(selectedInviteMethod));

  const [links, setLinks] = useState<{
    internal: string;
    external: string;
  } | null>(null);

  useEffect(() => {
    if (open) {
      setInviteMethod(inviteType);
    }
  }, [open, inviteType]);

  useEffect(() => {
    async function getLinks() {
      setLinks({
        internal: await getInviteLinkForTarget({
          type: "room",
          roomId: room.id,
        }),
        external: await getInviteLinkForTarget({
          type: "room-external",
          roomId: room.id,
        }),
      });
    }

    if (open) {
      getLinks().catch(console.error);
    }
  }, [open, pool.id, room]);

  const basicDialog = useMemo<KDialogConfig>(
    () => ({
      title: t("inviteMember.title"),
      content: (
        <div className={classes.dialogContentContainer}>
          <DialogContentText classes={{ root: classes.text }}>
            {t("inviteMember.subtitle")}
          </DialogContentText>
          <div className={classes.cardsContainer}>
            <DetailedActionCard
              title={t("inviteMember.internalMemberCardTitle")}
              subtitle={t("inviteMember.internalMemberCardSubtitle")}
              icon="users-right"
              onPress={() => setInviteMethod("internal")}
            />
            <DetailedActionCard
              title={t("inviteMember.externalMemberCardTitle")}
              subtitle={t("inviteMember.externalMemberCardSubtitle")}
              icon="users-right"
              onPress={() => setInviteMethod("external")}
            />
          </div>
        </div>
      ),
    }),
    [classes.cardsContainer, classes.dialogContentContainer, classes.text, t],
  );

  const dialogForInternalInvite = useMemo<KDialogConfig>(
    () => ({
      title: t("inviteMember.internalMember"),
      content: (
        <div className={classes.dialogContentContainer}>
          {links !== null ? (
            <InviteLinkInput
              label={t("inviteMember.linkInputLabelWithRoomName", {
                roomName: getExistingRoomRecordField(
                  room.record.properties,
                  "title",
                  "",
                ),
              })}
              link={links.internal}
            />
          ) : (
            // eslint-disable-next-line no-inline-styles/no-inline-styles
            <Preloader style={{ height: 55 }} />
          )}
          <DialogContentText
            classes={{ root: clsx(classes.text, classes.spacerTop) }}
          >
            {t("inviteMember.helperTextForRoomInvite")}
          </DialogContentText>
        </div>
      ),
    }),
    [
      classes.dialogContentContainer,
      classes.spacerTop,
      classes.text,
      links,
      room,
      t,
    ],
  );

  const dialogForExternalInvite = useMemo<KDialogConfig>(
    () => ({
      title: t("inviteMember.externalMember"),
      content: (
        <div className={classes.dialogContentContainer}>
          {links !== null ? (
            <InviteLinkInput
              label={t("inviteMember.linkInputLabelWithRoomName", {
                roomName: getExistingRoomRecordField(
                  room.record.properties,
                  "title",
                  "",
                ),
              })}
              link={links.external}
            />
          ) : (
            // eslint-disable-next-line no-inline-styles/no-inline-styles
            <Preloader style={{ height: 55 }} />
          )}
          <DialogContentText
            classes={{ root: clsx(classes.text, classes.spacerTop) }}
          >
            {t("inviteMember.helperTextForExternalMemberInvite")}
          </DialogContentText>
        </div>
      ),
    }),
    [
      classes.dialogContentContainer,
      classes.spacerTop,
      classes.text,
      links,
      room,
      t,
    ],
  );

  const dialog = useMemo(() => {
    if (selectedInviteMethod === undefined) {
      return basicDialog;
    }
    switch (selectedInviteMethod) {
      case "internal":
        return dialogForInternalInvite;
      case "external":
        return dialogForExternalInvite;
    }
  }, [
    basicDialog,
    dialogForExternalInvite,
    dialogForInternalInvite,
    selectedInviteMethod,
  ]);

  return (
    <KDialog
      open={open}
      onClose={onClose}
      size={KDialogSize.MEDIUM}
      {...dialog}
    />
  );
};

export { InviteInternalOrExternalCollaboratorDialog };
