import { alertReducers } from "@kraaft/shared/core/modules/alert/alertReducers";
import { appReducers } from "@kraaft/shared/core/modules/app/appReducers";
import { companyReducers } from "@kraaft/shared/core/modules/company/company.reducer";
import { directoryReducers } from "@kraaft/shared/core/modules/directory";
import { dummyReducer } from "@kraaft/shared/core/modules/dummy/dummy.reducer";
import { emojiReducer } from "@kraaft/shared/core/modules/emoji/emoji.reducer";
import { featureFlagReducers } from "@kraaft/shared/core/modules/featureFlags";
import { fileBucketReducers } from "@kraaft/shared/core/modules/fileBucket";
import { filterReducers } from "@kraaft/shared/core/modules/filter";
import { formReducers } from "@kraaft/shared/core/modules/form";
import { loaderReducers } from "@kraaft/shared/core/modules/loaders";
import {
  LoginSlice,
  persistedLoginReducer,
} from "@kraaft/shared/core/modules/login/login.slice";
import { mapOverlayReducers } from "@kraaft/shared/core/modules/mapOverlay";
import { memoryReducers } from "@kraaft/shared/core/modules/memory/memoryReducers";
import { messageReducers } from "@kraaft/shared/core/modules/message";
import { messageDataReducer } from "@kraaft/shared/core/modules/message/messageData/messageData.reducers";
import { offlineMessageReducers } from "@kraaft/shared/core/modules/message/offline";
import { miniMediaReducers } from "@kraaft/shared/core/modules/miniMedia";
import { modularFolderReducers } from "@kraaft/shared/core/modules/modularFolder";
import { modularFolderHistoryReducers } from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.reducers";
import { permissionRequestStrategyReducers } from "@kraaft/shared/core/modules/permissionRequestStrategy/permissionRequestStrategy.reducers";
import { PhotoTabSlice } from "@kraaft/shared/core/modules/photoTabConfig/photoTab.slice";
import { poolReducers } from "@kraaft/shared/core/modules/pool";
import { reportTemplateReducers } from "@kraaft/shared/core/modules/reportTemplate";
import { roomReducers } from "@kraaft/shared/core/modules/room";
import { roomCardReducers } from "@kraaft/shared/core/modules/roomCard/roomCard.reducers";
import { roomSchemaVisibilityReducers } from "@kraaft/shared/core/modules/roomSchemaVisibility";
import { schemaReducers } from "@kraaft/shared/core/modules/schema";
import { schemaTemplateReducers } from "@kraaft/shared/core/modules/schemaTemplate";
import { surveysReducers } from "@kraaft/shared/core/modules/surveys/surveys.reducers";
import { userReducers } from "@kraaft/shared/core/modules/user";
import { UserContactInfosSlice } from "@kraaft/shared/core/modules/userContactInfos";
import { userPoolReducers } from "@kraaft/shared/core/modules/userPool";

import { actorReducer } from "../../modules/auth/actor.redux";
import { poolMemberReducers } from "../../modules/poolMember/poolMember.reducers";

export const commonReducers = {
  app: appReducers,
  alert: alertReducers,
  room: roomReducers,
  user: userReducers,
  reportTemplate: reportTemplateReducers,
  pool: poolReducers,
  poolMember: poolMemberReducers,
  message: messageReducers,
  messageData: messageDataReducer,
  form: formReducers,
  fileBucket: fileBucketReducers,
  loader: loaderReducers,
  memory: memoryReducers,
  schema: schemaReducers,
  modularFolders: modularFolderReducers,
  schemaTemplate: schemaTemplateReducers,
  directory: directoryReducers,
  filter: filterReducers,
  miniMedia: miniMediaReducers,
  userPool: userPoolReducers,
  mapOverlay: mapOverlayReducers,
  roomSchemaVisibility: roomSchemaVisibilityReducers,
  featureFlag: featureFlagReducers,
  [PhotoTabSlice.name]: PhotoTabSlice.reducer,
  company: companyReducers,
  modularFolderHistory: modularFolderHistoryReducers,
  dummy: dummyReducer,
  permissionRequestStrategy: permissionRequestStrategyReducers,
  roomCard: roomCardReducers,
  offlineMessage: offlineMessageReducers,
  [UserContactInfosSlice.name]: UserContactInfosSlice.reducer,
  [LoginSlice.name]: persistedLoginReducer,
  emoji: emojiReducer,
  actor: actorReducer,
  survey: surveysReducers,
};
