import React, { useMemo } from "react";
import { type DragLayerMonitor, useDragLayer } from "react-dnd";
import { makeStyles } from "@mui/styles";

import { useMeshContext } from "@kraaft/helper-hooks";
import type { UnknownObject } from "@kraaft/shared/core/types";
import { Icon, Portal, Spacing } from "@kraaft/ui";
import { useDragLayerStyles } from "@kraaft/web/src/components/dragAndDrop/dragLayerBase.styles";
import { getTranslationStyle } from "@kraaft/web/src/components/kanban/board/kanbanDragLayer/kanbanDragLayer.utils";

import { OrderableListContext } from "../orderableList.context";
import type { OrderableListDragItem } from "../orderableList.types";

interface OrderableListDragLayerProps<T = UnknownObject> {
  RowRenderer: React.ComponentType<T>;
  withHandle?: boolean;
  freezeX?: boolean;
}

export const OrderableListDragLayer = <T extends UnknownObject>({
  RowRenderer,
  withHandle,
  freezeX,
}: OrderableListDragLayerProps<T>) => {
  const classes = useStyles();

  const { sourceType, identifier } = useMeshContext(OrderableListContext);
  const dragLayerClasses = useDragLayerStyles();

  const { item, currentOffset, initialOffset, isDragging } = useDragLayer(
    (monitor: DragLayerMonitor<OrderableListDragItem<T>>) => ({
      item: monitor.getItem() as OrderableListDragItem<T>,
      currentOffset: monitor.getSourceClientOffset(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      isDragging:
        monitor.getItemType() === sourceType &&
        monitor.getItem().listIdentifier === identifier,
    }),
  );

  const content = useMemo(
    () => (item !== null ? React.createElement(RowRenderer, item.data) : null),
    [RowRenderer, item],
  );

  if (!isDragging) {
    return null;
  }

  const offsetX = freezeX ? (initialOffset?.x ?? 0) : (currentOffset?.x ?? 0);
  const offsetY = currentOffset?.y ?? 0;

  return (
    <Portal>
      <div className={dragLayerClasses.layerBase}>
        <div
          style={getTranslationStyle({
            x: offsetX,
            y: offsetY,
          })}
        >
          <div
            className={classes.itemContainer}
            style={{ width: item.placeholderSize?.width }}
          >
            {withHandle && (
              <div className={classes.handleIconContainer}>
                <Icon name="dots-six" color="FONT_LOW_EMPHASIS" />
              </div>
            )}
            {content}
          </div>
        </div>
      </div>
    </Portal>
  );
};

const useStyles = makeStyles({
  itemContainer: {
    display: "flex",
    alignItems: "center",
  },
  handleIconContainer: {
    paddingRight: Spacing.S8,
  },
});
