import { createAction } from "@reduxjs/toolkit";

import {
  AnyRoomCard,
  PinnedRoomCardQuery,
  RoomCardCursor,
  RoomCardQueryContext,
  RoomMemberCard,
} from "@kraaft/shared/core/modules/roomCard/roomCard.state";

export const RoomCardActions = {
  subscribe: createAction<RoomCardQueryContext>("@roomCard/subscribe"),
  unsubscribe: createAction<RoomCardQueryContext>("@roomCard/unsubscribe"),
  subscribeToPinned: createAction<PinnedRoomCardQuery>(
    "@roomCard/subscribeToPinned",
  ),
  unsubscribeFromPinned: createAction<PinnedRoomCardQuery>(
    "@roomCard/unsubscribeFromPinned",
  ),
  subscribeToRoomMemberCard: createAction<{ roomId: string }>(
    "@roomCard/subscribeToRoomMemberCard",
  ),
  unsubscribeFromRoomMemberCard: createAction<{ roomId: string }>(
    "@roomCard/unsubscribeFromRoomMemberCard",
  ),
  loadMore: createAction<{ filterId: string }>("@roomCard/loadMore"),
  updateSearchText: createAction<{
    filterId: string;
    value: string | undefined;
  }>("@roomCard/updateSearchText"),
};

export const RoomCardStateActions = {
  setPageInitialState: createAction<{
    filterId: string;
    queryContext: RoomCardQueryContext;
  }>("@roomCard/state/setPageInitialState"),

  setIsLoading: createAction<{
    filterId: string;
    isLoading: boolean;
  }>("@roomCard/state/setIsLoading"),

  setIsLoadingMore: createAction<{
    filterId: string;
    isLoadingMore: boolean;
  }>("@roomCard/state/setLoadingMore"),

  setHasError: createAction<{
    filterId: string;
    hasError: boolean;
  }>("@roomCard/state/setHasError"),

  setFromSubscription: createAction<{
    filterId: string;
    roomCards: Record<string, AnyRoomCard>;
  }>("@roomCard/state/setFromSubscription"),

  setLoadedIds: createAction<{
    filterId: string;
    roomCards: Record<string, AnyRoomCard>;
  }>("@roomCard/state/setLoaded"),

  addToLoaded: createAction<{
    poolId: string;
    filterId: string;
    roomCards: Record<string, AnyRoomCard>;
  }>("@roomCard/state/addToLoaded"),

  setCursorForLastAtAll: createAction<{
    filterId: string;
    cursor: RoomCardCursor | undefined;
  }>("@roomCard/state/setCursorContext"),

  setCursorForFirstLoadedDoc: createAction<{
    filterId: string;
    cursor: RoomCardCursor | undefined;
  }>("@roomCard/state/setCursorForLastAtAll"),

  setCursorForLastFromSubscription: createAction<{
    filterId: string;
    cursor: RoomCardCursor | undefined;
  }>("@roomCard/state/setCursorForLastFromSubscription"),

  setHasMore: createAction<{ filterId: string; hasMore: boolean }>(
    "@roomCard/state/setHasMore",
  ),

  setAlreadyMoreLoaded: createAction<{
    filterId: string;
    alreadyMoreLoaded: boolean;
  }>("@roomCard/state/setAlreadyMoreLoaded"),

  setSearchText: createAction<{
    filterId: string;
    searchText: string | undefined;
  }>("@roomCard/state/setSearchText"),

  resetLoaded: createAction<{ filterId: string }>(
    "@roomCard/state/resetLoaded",
  ),
  setRoomCard: createAction<{ roomId: string; roomCard: AnyRoomCard }>(
    "@roomCard/state/setRoomCard",
  ),

  deleteRoomCard: createAction<{ roomId: string }>("@roomCard/deleteRoomCard"),

  pinRoom: createAction<{ roomId: string }>("@roomCard/state/PinRoom"),

  unpin: createAction<{ roomId: string }>("@roomCard/state/PinRoom"),

  resetPinnedState: createAction<{ poolId: string }>(
    "@roomCard/state/resetPinnedState",
  ),

  setPinnedRoomCards: createAction<{
    poolId: string;
    roomCards: Record<string, RoomMemberCard>;
  }>("@roomCard/state/setPinnedRoomCards"),
  setArePinnedRoomCardsLoading: createAction<{ is: boolean }>(
    "@roomCard/state/setArePinnedRoomCardsLoading",
  ),

  setPinned: createAction<{ poolId: string; roomId: string; at: Date }>(
    "@roomCard/state/setPinned",
  ),

  setUnpinned: createAction<{ poolId: string; roomId: string }>(
    "@roomCard/state/setUnpinned",
  ),
};
