import {
  BaseAggregate,
  BuiltUserDeclaredOperations,
  OfflineFeature,
} from "./optimistic/optimistic.types";
import { OptimisticHelper } from "./optimistic/optimisticHelper";
import { NamedTaskManager } from "./taskStore/taskManager";
import { registeredLogger } from "./logger";

export function DeclareOfflineFeature<Aggregate extends BaseAggregate>(
  name: string,
) {
  const logger = registeredLogger.current.createSubLogger(["Root"]);

  return <
    const DeclaredOperations extends BuiltUserDeclaredOperations,
    Operations extends {
      [K in keyof DeclaredOperations]: ReturnType<
        DeclaredOperations[K]["getOperation"]
      >;
    },
  >(
    declaredOperations: DeclaredOperations,
    taskManager: NamedTaskManager,
  ): OfflineFeature<Aggregate, Operations> => {
    const operations = Object.entries(declaredOperations).reduce(
      (acc, [key, operationBuilder]) => {
        const operation = operationBuilder.getOperation();
        acc[key] = operation;
        taskManager.register(key, operation.mutate);
        return acc;
      },
      {} as { [key: string]: unknown },
    ) as Operations;

    // eslint-disable-next-line complexity
    taskManager.onTaskSucceeded.register(async (task, result) => {
      const declaredOperation = operations[task.name];
      if (!declaredOperation) {
        return;
      }
      let oldIds: string[] | undefined;
      let newIds: string[] | undefined;
      if (declaredOperation.type === "custom" && declaredOperation.creates) {
        if (
          !OptimisticHelper.isValidCustomCreationResult(name, task.name, result)
        ) {
          return;
        }
        oldIds = task.payload.allocatedIds;
        newIds = result.ids;
      }
      if (declaredOperation.type === "creations") {
        if (!OptimisticHelper.isValidCreationResult(name, task.name, result)) {
          return;
        }
        oldIds = [...task.payload.ids, ...(task.payload.allocatedIds ?? [])];
        newIds = result;
        if (oldIds.length !== newIds?.length) {
          console.log("oldold", oldIds, newIds);
          logger.warn(
            `Your operation ${task.name} mutate does not return the right amount of ids, if you used creates, makes sure the created ids are included in the result of the mutate function`,
          );
        }
      }
      if (!oldIds || !newIds) {
        return;
      }
      await taskManager.edit((taskToEdit) => {
        const taskDeclaredOperation = operations[taskToEdit.name];
        if (!taskDeclaredOperation) {
          return;
        }
        for (let i = 0; i < oldIds.length; i += 1) {
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          const oldId = oldIds[i]!;
          const newId = newIds[i];
          if (!newId) {
            logger.error("Optimistically created more ids than persisted");
            return;
          }
          taskDeclaredOperation.replaceId(taskToEdit.payload, oldId, newId);
        }
      });
    });

    return {
      __aggregate: 0 as any,
      name,
      userDeclaredOperations: operations,
      taskManager,
    };
  };
}
