import { useMemo } from "react";

import type { InviteCollaboratorsProps } from "@kraaft/shared/components/inviteCollaborators/types";
import { Api } from "@kraaft/shared/core/services/api";

type Props =
  | {
      type: "pool";
      poolId: string;
    }
  | {
      type: "pool-external";
      poolId: string;
    }
  | {
      type: "room";
      roomId: string;
    }
  | {
      type: "room-external";
      roomId: string;
    };

export const getInviteLinkForTarget = async (props: Props) => {
  switch (props.type) {
    case "pool":
      return Api.generatePoolInviteLinkForStandard({ poolId: props.poolId });
    case "pool-external":
      return Api.generatePoolInviteLinkForExternal({ poolId: props.poolId });
    case "room":
      return Api.generateRoomInviteLinkForStandard({ roomId: props.roomId });
    case "room-external":
      return Api.generateRoomInviteLinkForExternal({ roomId: props.roomId });
  }
};

export const useInvitationTargetProps = (props: InviteCollaboratorsProps) =>
  useMemo(() => {
    const { open: _, onClose: _1, ...newInviteTargetProps } = props;

    return newInviteTargetProps;
  }, [props]);
