import { useCallback, useMemo, useState } from "react";
import { LayoutChangeEvent, StyleSheet, View } from "react-native";
import { chunk } from "lodash";

import { KColor, KColors, Spacing } from "../../constants";
import { HideVertical } from "../../display";
import { webOnlyStyle } from "../../utils";
import { COLOR_DOT_SIZE, ColorLine } from "./colorLine";

interface ExpandableColorPickerProps {
  value: KColor;
  onChange: (color: KColor) => void;
}

const MIN_MARGIN_BETWEEN_COLORS = Spacing.S8;

export const ExpandableColorPicker = ({
  value,
  onChange,
}: ExpandableColorPickerProps) => {
  const [show, setShow] = useState(false);

  const roll = useCallback(() => setShow((o) => !o), []);

  const [availableWidth, setAvailableWidth] = useState(-1);

  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    setAvailableWidth(event.nativeEvent.layout.width);
  }, []);

  const colorDotPerLine = Math.floor(
    availableWidth / (COLOR_DOT_SIZE + MIN_MARGIN_BETWEEN_COLORS),
  );

  const dotsLines = useMemo(() => {
    const colorsAndButton: string[] = [...KColors.Colors];
    colorsAndButton.splice(colorDotPerLine - 1, 0, "button");
    const chunked = chunk(colorsAndButton, colorDotPerLine);
    return chunked;
  }, [colorDotPerLine]);

  const remainingWidth = availableWidth - colorDotPerLine * COLOR_DOT_SIZE;
  const marginBetweenDots = remainingWidth / (colorDotPerLine - 1) / 2;

  const totalHeight =
    dotsLines.length * (COLOR_DOT_SIZE + marginBetweenDots * 2);

  const firstLine = dotsLines[0];
  const expandableLines = dotsLines.slice(1);

  return (
    <>
      <View onLayout={handleLayout} />
      <View
        style={[
          styles.root,
          {
            maxWidth: availableWidth,
          },
        ]}
      >
        {availableWidth === -1 || !firstLine ? null : (
          <>
            <ColorLine
              colors={firstLine}
              isOpen={show}
              margin={marginBetweenDots}
              onChange={onChange}
              toggleOpen={roll}
              value={value}
            />
            <HideVertical hidden={!show}>
              <View
                style={{
                  marginTop: marginBetweenDots * 2,
                  gap: marginBetweenDots * 2,
                  flexDirection: "column",
                }}
              >
                {expandableLines.map((line) => (
                  <ColorLine
                    key={line.join(",")}
                    colors={line}
                    isOpen={show}
                    margin={marginBetweenDots}
                    onChange={onChange}
                    toggleOpen={roll}
                    value={value}
                  />
                ))}
              </View>
            </HideVertical>
          </>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    overflow: "hidden",
    ...webOnlyStyle({ transition: "all 250ms" }),
  },
});
