import { ComponentProps, ReactNode, useCallback } from "react";
import { DragDropProvider } from "@dnd-kit/react";

import { DraggableOnDrop, DraggableOnEnd, DraggableOnOver } from "./dnd.types";

interface DragAndDropProviderProps {
  children: ReactNode;
}

export const DragAndDropProvider = ({ children }: DragAndDropProviderProps) => {
  const handleDragEnd = useCallback<
    NonNullable<ComponentProps<typeof DragDropProvider>["onDragEnd"]>
  >((event) => {
    const source = event.operation.source;
    const target = event.operation.target;

    const onEnd: DraggableOnEnd | undefined =
      event.operation.source?.data?.onEnd;
    onEnd?.(event.operation);

    if (!source || !target) {
      return;
    }

    const sourceType: string | undefined = event.operation.source?.data?.type;
    const targetType: string | undefined = event.operation.target?.data?.type;

    if (!sourceType || !targetType) {
      return;
    }

    if (target.accepts(source)) {
      event.operation.target?.data?.onDrop?.(source.data, event.operation);
    }

    const onDrop: DraggableOnDrop<any> | undefined =
      event.operation.source?.data?.onDrop;
    if (onDrop?.on.includes(targetType)) {
      onDrop.do(target.data, event.operation);
    }
  }, []);

  const handleDragOver = useCallback<
    NonNullable<ComponentProps<typeof DragDropProvider>["onDragOver"]>
  >((event) => {
    const source = event.operation.source;
    const target = event.operation.target;

    if (!source || !target) {
      return;
    }

    const sourceType: string | undefined = event.operation.source?.data?.type;
    const targetType: string | undefined = event.operation.target?.data?.type;

    if (!sourceType || !targetType) {
      return;
    }

    if (target.accepts(source)) {
      event.operation.target?.data?.onOver?.(source.data, event.operation);
    }
    const onOver: DraggableOnOver<any> | undefined =
      event.operation.source?.data?.onOver;
    if (onOver) {
      onOver.do(target.data, event.operation);
    }
  }, []);

  return (
    <DragDropProvider onDragOver={handleDragOver} onDragEnd={handleDragEnd}>
      {children}
    </DragDropProvider>
  );
};
