import { StyleSheet } from "react-native";

import { Color, ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  background: {
    flex: 1,
    height: "100%",
    backgroundColor: Color.WHITE,
  },

  filter: {
    margin: Spacing.S16,
  },

  codeButton: {
    borderRadius: 5,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    flexDirection: "row",
    alignItems: "center",
    minHeight: 55,
    paddingHorizontal: Spacing.S16,
    paddingVertical: Spacing.S4,
  },

  labelText: {
    lineHeight: FontSize.TITLE,
    fontSize: FontSize.SMALL,
    color: Color.GREY_TAUPE,
  },

  codeTextContainer: {
    paddingVertical: Spacing.S4,
  },

  codeText: {
    fontSize: FontSize.BODY,
  },

  sheetContent: {
    height: 250,
    width: 300,
  },
});
