import { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";

import { Color } from "@kraaft/ui";

type GradientBackgroundProps = {
  height: number;
};

export const GradientBackground = ({
  children,
  height,
}: PropsWithChildren<GradientBackgroundProps>) => {
  const classes = useStyles();

  return (
    <div className={classes.container} style={{ minHeight: height }}>
      {children}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    background: `linear-gradient(63deg, #2F4CC5 12.26%, ${Color.BLUE_AZURE} 79.79%)`,
  },
});
