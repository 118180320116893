import { call, delay, put, select, takeEvery } from "typed-redux-saga/macro";

import {
  NpsSurveyActions,
  NpsSurveyStateActions,
} from "@kraaft/shared/core/modules/surveys/npsSurvey/npsSurvey.actions";
import { NpsSurveyApi } from "@kraaft/shared/core/modules/surveys/npsSurvey/npsSurvey.api";
import { selectNpsSurveyResponseId } from "@kraaft/shared/core/modules/surveys/npsSurvey/npsSurvey.selectors";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

export function* npsSurveySagas() {
  yield* takeEvery(
    NpsSurveyActions.createNpsSurveyResponse,
    createNpsSurveyResponse,
  );
  yield* takeEvery(
    NpsSurveyActions.updateNpsSurveyResponse,
    updateNpsSurveyResponse,
  );
  yield* takeEvery(
    UserActions.userConnectedToFirebase,
    shouldDisplayNpsSurveyFormSaga,
  );
}

export function* createNpsSurveyResponse({
  payload,
}: ReturnType<typeof NpsSurveyActions.createNpsSurveyResponse>) {
  const { responseId } = yield* call(NpsSurveyApi.createNpsSurveyResponse, {
    note: payload.note,
  });

  yield* put(NpsSurveyStateActions.setNpsSurveyResponseId({ id: responseId }));
}

export function* updateNpsSurveyResponse({
  payload,
}: ReturnType<typeof NpsSurveyActions.updateNpsSurveyResponse>) {
  const responseId = yield* select(selectNpsSurveyResponseId);

  if (responseId === undefined) {
    return;
  }

  yield* call(NpsSurveyApi.updateNpsSurveyResponse, {
    responseId,
    target: payload.target,
    benefit: payload.benefit,
    missingFunctionality: payload.missingFunctionality,
  });
}

export function* shouldDisplayNpsSurveyFormSaga() {
  const { shouldDisplayNpsSurveyForm } = yield* call(
    NpsSurveyApi.getShouldDisplayNpsSurveyForm,
  );

  yield* delay(1000);

  if (shouldDisplayNpsSurveyForm) {
    yield* put(
      NpsSurveyStateActions.setShouldDisplayNpsSurveyForm({
        shouldDisplayNpsSurveyForm,
      }),
    );
  }
}
