import { useCallback } from "react";
import { useSelector } from "react-redux";

import { ModularFolderCard } from "@kraaft/shared/components/modularFolderCard";
import { ModularFolderChecker } from "@kraaft/shared/components/modularFolderCard/modularFolderChecker";
import { ModularFolderLeftIcon } from "@kraaft/shared/components/modularFolderCard/modularFolderLeftIcon";
import { FoldProps } from "@kraaft/shared/components/modularFolderCard/types";
import { ModularFolderCardDescription } from "@kraaft/shared/components/modularFolderList/modularFolderItem/modularFolderCardDescription";
import { ModularFolderItemProps } from "@kraaft/shared/components/modularFolderList/modularFolderItem/types";
import {
  useIsModularFolderSelected,
  useIsModularFoldersSelectionEnabled,
} from "@kraaft/shared/components/modularFolders/modularFoldersSelection.context";
import {
  Attachment,
  ATTACHMENT_TYPES,
} from "@kraaft/shared/core/modules/folder/attachmentTypes";
import {
  selectModularFolderAttachments,
  selectModularFolderFirstValidAttachmentColumn,
  selectModularFolderIconAndColor,
  selectModularFolderProperties,
  selectModularFolderTitle,
} from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { selectRoomTitle } from "@kraaft/shared/core/modules/room/roomSelectors";
import { filterAttachments } from "@kraaft/shared/core/utils";
import { useModularDrop } from "@kraaft/shared/core/utils/useModularDrop";

import {
  useModularFolderDropAcceptTypes,
  useModularFolderDropRejectTypes,
} from "./modularFolderItemUtils";

export const ModularFolderItem = ({
  roomId,
  schemaId,
  modularFolderId,
  rightIcon,
  openCarousel,
  interactionProps,
  users,
}: ModularFolderItemProps) => {
  const roomTitle = useSelector(selectRoomTitle(roomId));
  const modularFolderTitle = useSelector(
    selectModularFolderTitle(modularFolderId),
  );
  const modularFolderProperties = useSelector(
    selectModularFolderProperties(modularFolderId),
  );

  const potentialDropDestination = useSelector(
    selectModularFolderFirstValidAttachmentColumn(modularFolderId),
  );

  const attachments = useSelector(
    selectModularFolderAttachments(modularFolderId, ATTACHMENT_TYPES),
  );

  const { onDrop, onDropFiles } = useModularDrop({
    roomId,
    schemaId,
    modularFolderId,
  });

  const handleAttachmentClick = useCallback(
    (attachment: Attachment) => {
      switch (attachment.type) {
        case "image": {
          const pictureIndex = filterAttachments(attachments, [
            "image",
          ]).findIndex(
            (pictureAttachment) => pictureAttachment.id === attachment.id,
          );
          openCarousel?.(modularFolderId, pictureIndex);
          break;
        }

        default:
          break;
      }
    },
    [attachments, modularFolderId, openCarousel],
  );

  let foldProps: FoldProps = {};
  if (users) {
    foldProps = {
      enableFold: true,
      attachments,
      attachmentContext: {
        folderTitle: modularFolderTitle,
        roomTitle: roomTitle ?? "",
        users,
      },
      onAttachmentClick: handleAttachmentClick,
    };
  }

  const description = (
    <ModularFolderCardDescription
      schemaId={schemaId}
      recordProperties={modularFolderProperties ?? {}}
    />
  );
  const dropAcceptTypes = useModularFolderDropAcceptTypes(schemaId);
  const dropRejectTypes = useModularFolderDropRejectTypes(schemaId);

  const isSelectionEnabled = useIsModularFoldersSelectionEnabled();
  const isSelected = useIsModularFolderSelected(modularFolderId);

  const leftIcon = useSelector(
    selectModularFolderIconAndColor(modularFolderId),
  );
  const leftView = isSelectionEnabled ? (
    <ModularFolderChecker isSelected={isSelected} />
  ) : (
    <ModularFolderLeftIcon
      accessibilityLabel=""
      name={leftIcon.icon}
      color={leftIcon.color}
      onClick={interactionProps.onClickLeftIcon}
      disabled={!interactionProps.enableInteractions}
    />
  );

  return (
    <ModularFolderCard
      id="modularFolder-card"
      enableInteractions
      title={modularFolderTitle}
      description={description}
      leftView={leftView}
      rightIcon={rightIcon}
      {...foldProps}
      {...interactionProps}
      enableDrop
      onDrop={onDrop(potentialDropDestination)}
      onDropFiles={onDropFiles(potentialDropDestination)}
      dropAcceptTypes={dropAcceptTypes}
      dropRejectTypes={dropRejectTypes}
      dropSystemFile
    />
  );
};
