import { createReducer } from "@reduxjs/toolkit";

import { applyRecordUpdate } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { RoomModularRecord } from "@kraaft/shared/core/modules/room/roomState";
import { RoomCardStateActions } from "@kraaft/shared/core/modules/roomCard/roomCard.actions";
import {
  AnyRoomCard,
  RoomCardState,
} from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { nullId } from "@kraaft/shared/core/utils/utils";

const initialState: RoomCardState = {
  pages: {},
  pinnedRoomCardIds: {},
  arePinnedRoomCardsLoading: true,
  roomCards: {},
};

export const roomCardReducers = createReducer(initialState, ({ addCase }) => {
  addCase(RoomCardStateActions.setPageInitialState, (state, { payload }) => {
    state.pages[payload.filterId] = {
      cursorContext: {
        hasMore: false,
        alreadyMoreLoaded: false,
        firstLoaded: undefined,
        lastAtAll: undefined,
        lastFromSubscription: undefined,
        hasError: false,
      },
      query: {
        poolId: payload.queryContext.poolId,
        userId: payload.queryContext.userId,
        filters: payload.queryContext.filters,
        filterIdPrefix: payload.queryContext.filterIdPrefix,
      },
      idsFromLoaded: [],
      idsFromSubscription: [],
      isLoading: true,
      isLoadingMore: false,
    };
  });

  addCase(RoomCardStateActions.setFromSubscription, (state, { payload }) => {
    const newRoomCards = assignNewRoomCards(state.roomCards, payload.roomCards);
    state.roomCards = { ...state.roomCards, ...newRoomCards };
    const page = state.pages[payload.filterId];

    if (page) {
      const idsFromSubscription = Object.keys(payload.roomCards);
      page.idsFromSubscription = idsFromSubscription;
      page.idsFromLoaded = page.idsFromLoaded.filter(
        (id) => !idsFromSubscription.includes(id),
      );
    }
  });

  addCase(
    RoomCardStateActions.setCursorForLastFromSubscription,
    (state, { payload }) => {
      const page = state.pages[payload.filterId];

      if (page) {
        page.cursorContext.lastFromSubscription = payload.cursor;
      }
    },
  );

  addCase(RoomCardStateActions.setIsLoading, (state, { payload }) => {
    const page = state.pages[payload.filterId];

    if (page) {
      page.isLoading = payload.isLoading;
    }
  });

  addCase(RoomCardStateActions.addToLoaded, (state, { payload }) => {
    const newRoomCards = assignNewRoomCards(state.roomCards, payload.roomCards);
    state.roomCards = { ...state.roomCards, ...newRoomCards };
    const page = state.pages[payload.filterId];

    if (page) {
      page.idsFromLoaded = [
        ...page.idsFromLoaded,
        ...Object.keys(payload.roomCards).filter(
          (id) =>
            !state.pinnedRoomCardIds[payload.poolId]?.includes(id) &&
            !page.idsFromLoaded.includes(id) &&
            !page.idsFromSubscription.includes(id),
        ),
      ];
    }
  });

  addCase(RoomCardStateActions.setSearchText, (state, { payload }) => {
    const page = state.pages[payload.filterId];

    if (page) {
      page.query.filters.searchText = payload.searchText;
    }
  });

  addCase(RoomCardStateActions.resetLoaded, (state, { payload }) => {
    const page = state.pages[payload.filterId];

    if (page) {
      page.cursorContext.hasMore =
        page.cursorContext.hasMore || page.cursorContext.alreadyMoreLoaded;
      page.cursorContext.lastAtAll = page.cursorContext.lastFromSubscription;
      page.cursorContext.alreadyMoreLoaded = false;
      page.cursorContext.firstLoaded = undefined;
      page.idsFromLoaded = [];
    }
  });

  addCase(RoomCardStateActions.setHasMore, (state, { payload }) => {
    const page = state.pages[payload.filterId];

    if (page) {
      page.cursorContext.hasMore = payload.hasMore;
    }
  });

  addCase(RoomCardStateActions.setCursorForLastAtAll, (state, { payload }) => {
    const page = state.pages[payload.filterId];

    if (page) {
      page.cursorContext.lastAtAll = payload.cursor;
    }
  });

  addCase(RoomCardStateActions.setIsLoadingMore, (state, { payload }) => {
    const page = state.pages[payload.filterId];

    if (page) {
      page.isLoadingMore = payload.isLoadingMore;
    }
  });

  addCase(RoomCardStateActions.setAlreadyMoreLoaded, (state, { payload }) => {
    const page = state.pages[payload.filterId];

    if (page) {
      page.cursorContext.alreadyMoreLoaded = payload.alreadyMoreLoaded;
    }
  });

  addCase(
    RoomCardStateActions.setCursorForFirstLoadedDoc,
    (state, { payload }) => {
      const page = state.pages[payload.filterId];

      if (page) {
        page.cursorContext.firstLoaded = payload.cursor;
      }
    },
  );

  addCase(RoomCardStateActions.setLoadedIds, (state, { payload }) => {
    const page = state.pages[payload.filterId];

    if (page) {
      page.idsFromLoaded = Object.keys(payload.roomCards);
    }
  });

  addCase(RoomCardStateActions.setRoomCard, (state, { payload }) => {
    state.roomCards[payload.roomId] = payload.roomCard;
  });

  addCase(RoomCardStateActions.deleteRoomCard, (state, { payload }) => {
    delete state.roomCards[payload.roomId];
  });

  addCase(RoomCardStateActions.setHasError, (state, { payload }) => {
    const page = state.pages[payload.filterId];

    if (page) {
      page.cursorContext.hasError = payload.hasError;
    }
  });

  addCase(RoomCardStateActions.resetPinnedState, (state, { payload }) => {
    state.pinnedRoomCardIds[payload.poolId] = [];
    state.arePinnedRoomCardsLoading = true;
  });

  addCase(RoomCardStateActions.setPinnedRoomCards, (state, { payload }) => {
    state.roomCards = { ...state.roomCards, ...payload.roomCards };
    state.pinnedRoomCardIds[payload.poolId] = Object.keys(payload.roomCards);
  });

  addCase(
    RoomCardStateActions.setArePinnedRoomCardsLoading,
    (state, { payload }) => {
      state.arePinnedRoomCardsLoading = payload.is;
    },
  );

  addCase(RoomCardStateActions.setPinned, (state, { payload }) => {
    const roomCard = state.roomCards[payload.roomId];
    if (!roomCard || roomCard.type === "pool") {
      return;
    }

    roomCard.pinned = true;
    roomCard.pinnedAt = payload.at;

    const pinnedRoomCardIds = state.pinnedRoomCardIds[payload.poolId] ?? [];
    pinnedRoomCardIds.unshift(payload.roomId);

    state.pinnedRoomCardIds[payload.poolId] = pinnedRoomCardIds;
  });

  addCase(RoomCardStateActions.setUnpinned, (state, { payload }) => {
    const pinnedRoomCardIds = state.pinnedRoomCardIds[payload.poolId] ?? [];
    const index = pinnedRoomCardIds?.indexOf(payload.roomId);

    if (index !== -1) {
      pinnedRoomCardIds.splice(index, 1);
    }

    for (const page of Object.values(state.pages)) {
      if (
        !page.idsFromLoaded.includes(payload.roomId) &&
        !page.idsFromSubscription.includes(payload.roomId)
      ) {
        page.idsFromLoaded.push(payload.roomId);
      }
    }

    const roomCard = state.roomCards[payload.roomId];

    if (roomCard && roomCard.type === "member") {
      roomCard.pinned = false;
      roomCard.pinnedAt = undefined;
    }
  });

  // SIDE EFFECTS FROM ROOM ACTIONS
  addCase(roomActions.RoomStateActions.setRoomEmoji, (state, { payload }) => {
    const roomCard = state.roomCards[payload.roomId];

    if (roomCard) {
      roomCard.emoji = payload.emoji;
    }
  });

  addCase(roomActions.updateRoomVisibility, (state, { payload }) => {
    const roomCard = state.roomCards[payload.roomId];

    if (roomCard) {
      roomCard.visibility =
        payload.visibility === "administrator" ? "private" : payload.visibility;
    }
  });

  addCase(roomActions.updateRoomVisibilityFailure, (state, { payload }) => {
    const roomCard = state.roomCards[payload.roomId];

    if (roomCard) {
      roomCard.visibility =
        payload.visibility === "administrator" ? "private" : payload.visibility;
    }
  });

  addCase(roomActions.updateRoomRecord, (state, { payload }) => {
    const roomCard = state.roomCards[payload.roomId];

    if (roomCard) {
      state.roomCards[payload.roomId] = {
        ...roomCard,
        ...projectRoomCardPropertiesUpdates(roomCard, payload.update),
      };

      applyRecordUpdate(roomCard.properties, payload.update);
    }
  });

  addCase(roomActions.updateRoomRecordFailure, (state, { payload }) => {
    const roomCard = state.roomCards[payload.roomId];

    if (roomCard) {
      state.roomCards[payload.roomId] = {
        ...roomCard,
        ...projectRoomCardPropertiesUpdates(roomCard, payload.rollback),
        properties: payload.rollback,
      };
    }
  });

  addCase(roomActions.markRoomUnread, (state, { payload }) => {
    const roomCard = state.roomCards[payload.roomId];

    if (roomCard && roomCard.type === "member") {
      roomCard.unread = true;
    }
  });

  addCase(roomActions.markRoomUnreadFailure, (state, { payload }) => {
    const roomCard = state.roomCards[payload.roomId];
    if (roomCard && roomCard.type === "member") {
      roomCard.unread = payload.oldValue;
    }
  });

  addCase(roomActions.markRoomRead, (state, { payload }) => {
    const roomCard = state.roomCards[payload.roomId];

    if (roomCard && roomCard.type === "member") {
      roomCard.unread = false;
    }
  });

  addCase(roomActions.markRoomReadFailure, (state, { payload }) => {
    const roomCard = state.roomCards[payload.roomId];

    if (roomCard && roomCard.type === "member") {
      roomCard.unread = payload.oldValue;
    }
  });

  addCase(
    roomActions.RoomArchiveStateActions.setArchived,
    (state, { payload }) => {
      const roomCard = state.roomCards[payload.roomId];

      if (roomCard) {
        roomCard.archived = true;
      }
    },
  );

  addCase(
    roomActions.RoomArchiveStateActions.setUnarchived,
    (state, { payload }) => {
      const roomCard = state.roomCards[payload.roomId];

      if (roomCard) {
        roomCard.archived = false;
      }
    },
  );

  addCase(
    roomActions.RoomStateActions.setNotificationFilter,
    (state, { payload }) => {
      const roomCard = state.roomCards[payload.roomId];
      if (roomCard && roomCard.type === "member") {
        roomCard.notificationSource = payload.notificationFilter;
      }
    },
  );

  addCase(roomActions.RoomStateActions.roomDeleted, (state, { payload }) => {
    delete state.roomCards[payload.roomId];
  });
});

function assignNewRoomCards(
  existing: Record<string, AnyRoomCard>,
  received: Record<string, AnyRoomCard>,
) {
  const newRoomCards: Record<string, AnyRoomCard> = {};
  for (const [key, value] of Object.entries(received)) {
    if (
      existing[key]?.type === "member" &&
      existing[key].pinned &&
      value.type === "pool"
    ) {
      continue;
    }
    newRoomCards[key] = value;
  }

  return newRoomCards;
}

function projectRoomCardPropertiesUpdates(
  currentRoomCardRecord: AnyRoomCard,
  updates: Partial<RoomModularRecord["properties"]>,
): {
  title: string;
  statusId: string;
  responsibleId: string;
  labelIds: string[];
} {
  return {
    title: updates.title ? updates.title.value : currentRoomCardRecord.title,
    statusId: updates.status
      ? (updates.status.value?.[0] ?? nullId)
      : currentRoomCardRecord.statusId,
    responsibleId: updates.responsible
      ? (updates.responsible.value?.[0] ?? nullId)
      : currentRoomCardRecord.responsibleId,
    labelIds: updates.labels
      ? (updates.labels.value ?? [])
      : currentRoomCardRecord.labelIds,
  };
}
