import { type PropsWithChildren, useMemo } from "react";
import { View } from "react-native";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { morphClassNameAsStyle } from "../../../../utils/morphClassNameAsStyle";
import type { ScrollContentWrapperProps } from "./types";

export const ScrollContentWrapper = ({
  children,
  multiline,
  inputStyle,
}: PropsWithChildren<ScrollContentWrapperProps>) => {
  const classes = useStyles();

  const containerStyles = useMemo(
    () => [
      inputStyle,
      morphClassNameAsStyle(
        clsx(
          classes.container,
          multiline
            ? classes.containerWithTextArea
            : classes.containerWithInput,
        ),
      ),
    ],
    [
      inputStyle,
      classes.container,
      classes.containerWithTextArea,
      classes.containerWithInput,
      multiline,
    ],
  );

  return <View style={containerStyles}>{children}</View>;
};

const useStyles = makeStyles({
  container: {
    gridArea: "1 / 1 / 1 / 1",
    pointerEvents: "none",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",

    minWidth: "auto",

    "& > *": {
      pointerEvents: "none",
    },
  },
  containerWithInput: {
    "& > *": {
      whiteSpace: "pre",
    },
  },
  containerWithTextArea: {
    "& > *": {
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
    },
  },
});
