import {
  ActionCreatorWithPayload,
  createAction,
  createReducer,
  createSelector,
  Reducer,
} from "@reduxjs/toolkit";
import { memoize } from "lodash";
import { Saga } from "redux-saga";

import {
  BaseAggregate,
  DependenciesFromDeclaredOperations,
  OfflineFeature,
  OptimisticOperation,
  UserDeclaredOperations,
} from "../optimistic/optimistic.types";
import { Task } from "../taskStore/task";
import { createReduxBundle } from "./reduxBundle";
import {
  ReduxState,
  ReduxStateActions,
  UserProvidedSelectors,
} from "./reduxBundle.types";

export interface FeatureOptimisticState {
  operations: OptimisticOperation[];
}

export type IdCorrespondance = Record<string, string>;

export type MutateResults = Record<string, any>;

export interface OptimisticState {
  features: Record<string, FeatureOptimisticState>;
  results: MutateResults;
  idCorrespondance: IdCorrespondance;
}

const REDUCER_NAME = "__offline-feature-reducer" as const;

export class OfflineReduxBundle {
  constructor(
    private readonly errorAction: ActionCreatorWithPayload<{
      feature: string;
      task: Task;
      error: any;
    }>,
    private readonly resetAction: ActionCreatorWithPayload<any>,
  ) {}

  private getFeature(state: OptimisticState, feature: string) {
    const existing = state.features[feature];
    if (existing) {
      return existing;
    }
    const created: OptimisticState["features"][string] = { operations: [] };
    state.features[feature] = created;
    return created;
  }

  private deleteOperations(
    state: OptimisticState,
    payload: {
      feature: string;
      operationIndexes: number[];
    },
  ) {
    const feature = this.getFeature(state, payload.feature);

    for (const index of [...payload.operationIndexes].reverse()) {
      const operation = feature.operations[index];
      feature.operations.splice(index, 1);
      if (operation) {
        delete state.results[operation.task.id];
      }
    }
  }

  private readonly actions = {
    deleteOperations: createAction<{
      feature: string;
      operationIndexes: number[];
    }>("offline-feature/delete"),
    seedOperations: createAction<{
      feature: string;
      operations: OptimisticOperation[];
    }>("offline-feature/seed-operations"),
    addOperation: createAction<{
      feature: string;
      operation: OptimisticOperation;
    }>("offline-feature/add-operation"),
    removeDependentOperations: createAction<{
      feature: string;
      dependencies: string[];
    }>("offline-feature/remove-dependent-operations"),
    setOperationMutateResult: createAction<{
      feature: string;
      id: string;
      result: any;
    }>("offline-feature/set-operation-mutate-result"),
    replaceOperationTargetId: createAction<{
      feature: string;
      ids: string[];
      by: string[];
    }>("offline-feature/replace-operation-target-id"),
  };

  private getState: (() => ReduxState) | undefined;
  private sagaInjector: ((saga: Saga) => void) | undefined;
  private sagasToBeBooted: Array<Saga> = [];

  private bootUnbootedSagas() {
    if (!this.sagaInjector) {
      return;
    }

    let saga = this.sagasToBeBooted.pop();
    while (saga) {
      this.sagaInjector(saga);
      saga = this.sagasToBeBooted.pop();
    }
  }

  private addSaga(saga: Saga) {
    this.sagasToBeBooted.push(saga);
    this.bootUnbootedSagas();
  }

  private registerSagaInjector(sagaInjector: (saga: Saga) => void) {
    this.sagaInjector = sagaInjector;
    this.bootUnbootedSagas();
  }

  inject(
    getState: () => ReduxState,
    injector: (key: string, reducer: Reducer) => void,
    sagaInjector: (saga: Saga) => void,
  ) {
    this.getState = getState;
    this.registerSagaInjector(sagaInjector);

    const reducer = createReducer<OptimisticState>(
      { features: {}, idCorrespondance: {}, results: {} },
      ({ addCase, addMatcher }) => {
        addCase(this.actions.deleteOperations, (state, { payload }) => {
          this.deleteOperations(state, payload);
        });

        addCase(this.actions.seedOperations, (state, { payload }) => {
          state.features[payload.feature] = {
            operations: payload.operations,
          };
        });

        addCase(this.actions.addOperation, (state, { payload }) => {
          const feature = this.getFeature(state, payload.feature);

          feature.operations.push(payload.operation);
        });

        addCase(this.actions.setOperationMutateResult, (state, { payload }) => {
          state.results[payload.id] = payload.result;
        });

        addCase(
          this.actions.removeDependentOperations,
          (state, { payload }) => {
            const feature = this.getFeature(state, payload.feature);

            feature.operations = feature.operations.filter(
              (operation) =>
                !operation.task.dependencies.some((dependency) =>
                  payload.dependencies.includes(dependency),
                ),
            );
          },
        );

        addCase(this.actions.replaceOperationTargetId, (state, { payload }) => {
          const feature = this.getFeature(state, payload.feature);

          for (const operation of feature.operations) {
            const declaredOperation =
              this.featureOperations[payload.feature]?.[operation.task.name];
            if (!declaredOperation) {
              continue;
            }
            for (let i = 0; i < payload.ids.length; i += 1) {
              const oldId = payload.ids[i];
              const newId = payload.by[i];

              if (!oldId || !newId) {
                continue;
              }

              state.idCorrespondance[oldId] = newId;

              declaredOperation.replaceId(operation.task.payload, oldId, newId);
            }
            if (declaredOperation.type === "custom") {
              operation.task.dependencies = declaredOperation.gatherIds(
                operation.task.payload,
              );
            }
          }
        });

        addCase(this.resetAction, (state) => {
          state.features = {};
        });

        addMatcher(
          (action): action is ReturnType<ReduxStateActions<any>["set"]> =>
            action.type.startsWith("OfflineSetter/"),
          (state, { payload }) => {
            this.deleteOperations(state, payload);
          },
        );
      },
    );

    injector(REDUCER_NAME, reducer);
  }

  private readonly featureOperations: Record<string, UserDeclaredOperations> =
    {};

  private registerFeatureOperations(
    feature: string,
    operations: UserDeclaredOperations,
  ) {
    this.featureOperations[feature] = operations;
  }

  selectIdCorrespondance(state: any) {
    return (state[REDUCER_NAME] as OptimisticState).idCorrespondance;
  }

  selectCorrespondingId = memoize((id: string) => {
    return createSelector(
      (state) => (state[REDUCER_NAME] as OptimisticState).idCorrespondance,
      (correspondance) => correspondance[id] ?? id,
    );
  });

  private selectMutateResults(state: any) {
    return (state[REDUCER_NAME] as OptimisticState).results;
  }

  private createSelectorForFeature(feature: string) {
    return createSelector(
      (state: any) => (state[REDUCER_NAME] as OptimisticState).features,
      (features) =>
        features[feature] ?? {
          operations: [],
        },
    );
  }

  create<
    Aggregate extends BaseAggregate,
    DeclaredOperations extends UserDeclaredOperations,
  >(
    feature: OfflineFeature<Aggregate, DeclaredOperations>,
    userProvidedSelectors: UserProvidedSelectors<Aggregate>,
    ...args: DependenciesFromDeclaredOperations<DeclaredOperations> extends never
      ? []
      : [
          extra: (
            getState: () => ReduxState,
            selectAggregate: (
              id: string,
            ) => (state: ReduxState) => Aggregate | undefined,
            selectAggregates: (state: ReduxState) => Record<string, Aggregate>,
          ) => DependenciesFromDeclaredOperations<DeclaredOperations>,
        ]
  ) {
    this.registerFeatureOperations(
      feature.name,
      feature.userDeclaredOperations,
    );
    const { Actions, Selectors, StateActions, saga } = createReduxBundle<
      Aggregate,
      DeclaredOperations
    >(
      feature.name,
      feature.userDeclaredOperations,
      feature.taskManager,
      () => this.getState?.(),
      this.selectMutateResults,
      this.createSelectorForFeature(feature.name),
      this.selectIdCorrespondance,
      this.actions,
    )(userProvidedSelectors, this.errorAction, this.resetAction, args[0]);

    this.addSaga(saga);

    return { Actions, Selectors, StateActions };
  }
}
