import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import { Pressable, View, Text } from "react-native";
import { useCountryCodes } from "@kraaft/shared/components/auth/countryPicker/countryCodeUtils";
import { styles } from "./countryPicker.styles";
import { getDeviceCountry } from "@kraaft/web/src/core/utils";
import { CountryPickerSheet } from "@kraaft/shared/components/auth/countryPicker/countryPickerSheet";

interface Props {
  selectedCode: string;
  onChangeText?: (value: string) => void;
  onChangeCode: (value: string) => void;
  error?: boolean;
}

const CountryPicker = ({ selectedCode, onChangeCode }: Props) => {
  const { t } = useTranslation();
  const sheetAnchor = useRef(null);

  const { element, open } = CountryPickerSheet({
    selectedCode,
    onChangeCode,
  }).use({});

  const { countryCodeMap } = useCountryCodes();

  const navigatorCountry = getDeviceCountry();

  useEffect(() => {
    if (navigatorCountry) {
      const navigatorCountryCode = countryCodeMap[navigatorCountry]?.dial_code;
      if (navigatorCountryCode) {
        onChangeCode(navigatorCountryCode);
      }
    }
  }, [navigatorCountry, countryCodeMap, onChangeCode]);

  return (
    <View ref={sheetAnchor}>
      <Pressable
        onPress={() => open({ anchor: sheetAnchor })}
        style={styles.codeButton}
        accessibilityLabel={t("signIn.phoneNumberPrefixLabel")}
      >
        <View>
          <Text style={styles.labelText}>
            {t("signIn.phoneNumberPrefixLabel")}
          </Text>
          <View style={styles.codeTextContainer}>
            <Text style={styles.codeText}>{selectedCode}</Text>
          </View>
        </View>
      </Pressable>
      {element}
    </View>
  );
};

export { CountryPicker };
