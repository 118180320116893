import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { RoomFiltersSheet } from "@kraaft/shared/components/filters/roomFilters.sheet";
import { RoomFiltersFooter } from "@kraaft/shared/components/filters/roomFiltersFooter";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { SearchBar } from "@kraaft/shared/components/searchBar/searchBar";
import { useKeyboardState } from "@kraaft/shared/core/utils/useKeyboardState";
import { Spacing } from "@kraaft/ui";

const SCROLLVIEW_LIMIT = 10;
const LABELS_LIMIT = 15; // technical limit

interface RoomFiltersScrollableListProps<T extends string> {
  headerTitle: string;
  items: KSelectableListItem<T>[];
  selected: Array<T>;
  setSelected: (selected: Array<T>) => void;
  isMultiple?: boolean;

  onFiltered: () => void;
  onErase: () => void;
  canErase: boolean;
  onBack: () => void;
}

type WithSearchBar =
  | {
      withSearchBar: true;
      searchText: string;
      setSearchText: (newSearchText: string) => void;
      totalItemCount: number;
    }
  | { withSearchBar?: false };

export const RoomFiltersScrollableList = <T extends string>({
  headerTitle,
  items,
  selected,
  setSelected,
  isMultiple,
  onFiltered,
  onErase,
  canErase,
  onBack,
  ...props
}: RoomFiltersScrollableListProps<T> & WithSearchBar) => {
  const { t } = useTranslation();
  const [isKeyboardOpen] = useKeyboardState();

  const preferFlatListOverScrollView = isNative()
    ? (props.withSearchBar ? props.totalItemCount : items.length) >
      SCROLLVIEW_LIMIT
    : true;

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [
      styles.container,
      preferFlatListOverScrollView ? styles.flatListContainer : undefined,
    ],
    [preferFlatListOverScrollView],
  );

  const SheetContentComponent = useMemo(
    () =>
      preferFlatListOverScrollView
        ? RoomFiltersSheet.GrowingContent
        : RoomFiltersSheet.Content,
    [preferFlatListOverScrollView],
  );

  return (
    <View style={containerStyle}>
      <RoomFiltersSheet.Header onClose={onBack} withBackBehavior>
        {headerTitle}
      </RoomFiltersSheet.Header>
      {props.withSearchBar && (
        <View style={styles.searchBar}>
          <SearchBar
            isStatic
            withPlaceholderIcon
            withCancelButton={isKeyboardOpen}
            value={props.searchText}
            onChange={props.setSearchText}
            placeholder={t("search")}
          />
        </View>
      )}
      <SheetContentComponent noPadding maxHeight={400}>
        <KSelectionList
          items={items}
          isMultiple={isMultiple}
          selected={selected}
          setSelected={setSelected}
          disableScroll={!preferFlatListOverScrollView}
          keyboardShouldPersistTaps="handled"
          selectionLimit={LABELS_LIMIT}
        />
      </SheetContentComponent>
      <RoomFiltersFooter
        onFiltered={onFiltered}
        onErase={onErase}
        canErase={canErase}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: Spacing.S16,
    maxHeight: "100%",
  },
  flatListContainer: {
    height: "100%",
  },
  searchBar: {
    marginHorizontal: Spacing.S16,
  },
});
