import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Stepper } from "@kraaft/shared/components/stepper";
import { Transition } from "@kraaft/shared/components/transition";
import { useSubscribeToRoom } from "@kraaft/shared/core/modules/room/hooks/subscribeToRoom.hook";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { Button, PortalHost, Preloader } from "@kraaft/ui";

import {
  ADD_CONVERSATION_DETAILS_PORTAL_HOSTNAME,
  RoomDetails,
} from "./roomDetails";
import { RoomLocation } from "./roomLocation";
import { RoomMembers } from "./roomMembers";
import { RoomName } from "./roomName";

import { useStyles } from "./createRoomFlow.panelContent.styles";

type CreateRoomFlowRouteKey =
  | "room-name"
  | "room-members"
  | "room-location"
  | "room-details";

interface RouteDefinition {
  component: React.ReactNode;
  index: number;
  name: string;
}

export interface CreateRoomFlowPanelContentBaseProps {
  onClose: () => void;
  panelWidth: number;
}

interface CreateRoomFlowPanelContentProps
  extends CreateRoomFlowPanelContentBaseProps {
  poolId: string;
  labelIds: string[] | undefined;
}

const ConnectedCreateRoomFlowPanelContent = (
  props: CreateRoomFlowPanelContentProps,
) => {
  const { onClose, panelWidth, poolId, labelIds } = props;
  const { t } = useTranslation();
  const navigationService = useNavigationService();

  const classes = useStyles({ panelWidth });

  const [routeKey, setRouteKey] = useState<CreateRoomFlowRouteKey>("room-name");
  const [roomId, setRoomId] = useState<string>();

  useSubscribeToRoom(roomId);

  const routes = useMemo<Record<CreateRoomFlowRouteKey, RouteDefinition>>(
    () => ({
      "room-name": {
        component: (
          <RoomName
            poolId={poolId}
            labelIds={labelIds}
            onRoomCreated={(createdRoomId) => {
              setRoomId(createdRoomId);
              setRouteKey("room-location");
              navigationService.navigate("Conversation", {
                roomId: createdRoomId,
                preserveDetails: false,
                openedTab: "folder",
              });
            }}
          />
        ),
        index: 0,
        name: t("createRoom.roomName.title"),
      },
      "room-members": {
        component: roomId ? (
          <RoomMembers
            poolId={poolId}
            roomId={roomId}
            onMembersAdded={() => setRouteKey("room-details")}
          />
        ) : (
          <Preloader absoluteFill transparent />
        ),
        index: 2,
        name: t("createRoom.roomMembers.title"),
      },
      "room-location": {
        component: roomId ? (
          <RoomLocation
            roomId={roomId}
            onNext={() => setRouteKey("room-members")}
          />
        ) : (
          <Preloader absoluteFill transparent />
        ),
        index: 1,
        name: t("createRoom.roomLocation.title"),
      },
      "room-details": {
        component: roomId ? (
          <RoomDetails poolId={poolId} roomId={roomId} onClose={onClose} />
        ) : (
          <Preloader absoluteFill transparent />
        ),
        index: 3,
        name: t("createRoom.roomDetails.title"),
      },
    }),
    [labelIds, navigationService, onClose, poolId, roomId, t],
  );

  const routeCount = useMemo(() => Object.keys(routes).length, [routes]);
  const currentRoute = useMemo(() => routes[routeKey], [routeKey, routes]);

  return (
    <div className={classes.panelContent} id="ide2e-create-room-panel">
      <div className={classes.headerContainer}>
        <div className={classes.stepperContainer}>
          <Stepper steps={routeCount} selected={currentRoute.index} />
          <Button
            icon="x-close"
            variant="QUATERNARY"
            onPress={onClose}
            accessibilityLabel={t("close")}
          />
        </div>
        <span className={classes.name}>{currentRoute.name}</span>
      </div>
      <Transition identifier={routeKey}>{currentRoute.component}</Transition>
      <PortalHost hostname={ADD_CONVERSATION_DETAILS_PORTAL_HOSTNAME} />
    </div>
  );
};

export { ConnectedCreateRoomFlowPanelContent };
