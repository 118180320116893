import { useRef } from "react";

export function generatePortalKey() {
  return Date.now() + Math.random().toString(36);
}

export function usePortalKey() {
  const key = useRef(generatePortalKey());

  return key.current;
}
