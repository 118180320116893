import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { MapTrackingInfo } from "@kraaft/shared/components/mapController/types";
import { useAlert } from "@kraaft/shared/core/modules/alert/useAlert";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import { screenshotElement } from "@kraaft/shared/core/utils/screenshotElement";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

export function useMapScreenshoter(
  screenshoterId: string,
  trackingInfo: MapTrackingInfo,
) {
  const { t } = useTranslation();
  const { alertError } = useAlert();

  const [screenshotting, setScreenshotting] = useState(false);

  const handleScreenshot = useCallback(async () => {
    trackEvent({ eventName: "Download Map", ...trackingInfo });

    const mapElement = document.getElementById(screenshoterId);

    if (!mapElement) {
      return;
    }

    try {
      setScreenshotting(true);
      const file = await screenshotElement(mapElement, {
        ignoreElements: (element: Element) => {
          if (element.tagName.toLowerCase() === "div") {
            if (element.className.includes("gmnoprint")) {
              return true;
            }
            if (element.className.includes("gm-style-cc")) {
              return true;
            }
          }

          return false;
        },
      });
      await fileSaver.download(file, `${t("mapScreenshot.filename")}.png`);
    } catch (e) {
      console.log("error screenshoting", e);
      alertError(e, "useMapScreenshoter");
    }
    setScreenshotting(false);
  }, [alertError, screenshoterId, t, trackingInfo]);

  return {
    downloadScreenshot: handleScreenshot,
    isScreenshoting: screenshotting,
  };
}
