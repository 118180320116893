import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  Droppable,
  DroppableChildrenRenderer,
  DroppableItem,
} from "@kraaft/shared/components/droppable";
import { ModularFolderCardContent } from "@kraaft/shared/components/modularFolderCard/modularFolderCardContent";
import { ModularFolderCardProps } from "@kraaft/shared/components/modularFolderCard/types";
import { mapFileTypeToDraggableMessageType } from "@kraaft/shared/components/modularFolderList/modularFolderItem/modularFolderItemUtils";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { useToggle } from "@kraaft/shared/core/utils/hooks";
import { DraggableMessageType } from "@kraaft/ui";

const ModularFolderCard = (props: ModularFolderCardProps) => {
  const {
    id,
    enableInteractions,
    enableDrop,
    onDrop,
    onDropFiles,
    dropAcceptTypes,
    dropRejectTypes,
    dropSystemFile,
  } = props;

  const [expanded, toggleExpanded] = useToggle(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDrop = useCallback(
    (item: DroppableItem) => {
      if (dropAcceptTypes?.includes(item.type)) {
        onDrop?.(item);
        toggleExpanded(true);
      } else {
        dispatch(
          showError({
            title: t(
              item.type === DraggableMessageType.MessageDocument
                ? "depositHereRejected.toast.document"
                : "depositHereRejected.toast.media",
            ),
          }),
        );
      }
    },
    [onDrop, toggleExpanded, dropAcceptTypes, dispatch, t],
  );

  const handleDropFiles = useCallback(
    (files: ModernFile<LocalPath>[]) => {
      const acceptedFiles: ModernFile<LocalPath>[] = [];
      const rejectedTypes: DraggableMessageType[] = [];
      for (const file of files) {
        const draggableType = mapFileTypeToDraggableMessageType(
          file.contentType,
        );
        if (draggableType && dropAcceptTypes?.includes(draggableType)) {
          acceptedFiles.push(file);
        } else if (draggableType) {
          rejectedTypes.push(draggableType);
        }
      }

      if (acceptedFiles.length > 0) {
        onDropFiles?.(acceptedFiles);
        toggleExpanded(true);
      }

      if (rejectedTypes.length > 0) {
        const hasDocumentRejectedTypes = rejectedTypes.some(
          (rejectedType) =>
            rejectedType === DraggableMessageType.MessageDocument,
        );

        dispatch(
          showError({
            title: t(
              hasDocumentRejectedTypes
                ? "depositHereRejected.toast.document"
                : "depositHereRejected.toast.media",
            ),
          }),
        );
      }
    },
    [onDropFiles, toggleExpanded, dropAcceptTypes, dispatch, t],
  );

  const renderDroppableContent = useCallback<DroppableChildrenRenderer>(
    ({ isActive, canDrop, draggedType }) => {
      return (
        <ModularFolderCardContent
          {...props}
          expanded={expanded}
          toggleExpanded={toggleExpanded}
          enableInteractions={enableInteractions}
          isDropActive={isActive}
          canDrop={canDrop}
          draggedType={draggedType}
        />
      );
    },
    [enableInteractions, expanded, props, toggleExpanded],
  );

  if (enableDrop) {
    return (
      <Droppable
        id={id}
        onDrop={handleDrop}
        onDropFiles={handleDropFiles}
        acceptType={[...dropAcceptTypes, ...dropRejectTypes]}
        acceptSystemFile={dropSystemFile}
      >
        {renderDroppableContent}
      </Droppable>
    );
  }
  if (enableInteractions) {
    return (
      <ModularFolderCardContent
        {...props}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        enableInteractions={enableInteractions}
      />
    );
  }
  return (
    <ModularFolderCardContent
      {...props}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
    />
  );
};

export { ModularFolderCard };
