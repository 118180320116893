import { useSelector } from "react-redux";

import type { InviteMemberFlowProps } from "@kraaft/shared/components/memberList/inviteMemberFlow";
import { selectOnePool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectRoom } from "@kraaft/shared/core/modules/room/selectors";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { InviteInternalOrExternalCollaboratorDialog } from "@kraaft/web/src/components/kDialog/customs/inviteInternalOrExternalCollaboratorDialog";

export const useInviteMemberFlow = (props: InviteMemberFlowProps) => {
  const [isInviteRoomMemberOpen, openInviteRoomMember, closeInviteRoomMember] =
    useBooleanState();

  const room = useSelector(
    selectRoom(props.type === "room" ? props.roomId : ""),
  );
  const pool = useSelector(selectOnePool(room?.poolId));

  const currentUserId: string | undefined = useSelector(selectCurrentUserId);

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    closeInviteRoomMember();
  };

  return {
    open: openInviteRoomMember,
    element:
      !pool || !room || !currentUserId || props.type !== "room" ? null : (
        <InviteInternalOrExternalCollaboratorDialog
          pool={pool}
          room={room}
          senderUserId={currentUserId}
          open={isInviteRoomMemberOpen}
          onClose={handleClose}
          source={props.source}
          inviteType={props.inviteType}
        />
      ),
  };
};
