import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { DetailedActionCard } from "@kraaft/shared/components/detailedActionCard";
import { InviteCollaborators } from "@kraaft/shared/components/inviteCollaborators";
import { useInviteMemberFlow } from "@kraaft/shared/components/memberList/inviteMemberFlow";
import { RoomInvitationSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { ColorStyle, Text } from "@kraaft/ui";

import { styles } from "./missingSomeone.styles";

interface Props {
  poolId: string;
  roomId: string | undefined;
  source: RoomInvitationSource;
}

const MissingSomeone = (props: Props) => {
  const { poolId, roomId, source } = props;
  const { t } = useTranslation();

  const { open: openInviteMemberFlow, element: InviteMemberFlow } =
    useInviteMemberFlow({
      roomId: roomId ?? "",
      source,
      type: "room",
    });

  const [
    isInviteCollaboratorsOpen,
    openInviteCollaborators,
    closeInviteCollaborators,
  ] = useBooleanState();

  return (
    <>
      <View style={styles.container}>
        <Text
          weight="medium"
          size="BODY"
          color="FONT_HIGH_EMPHASIS"
          style={styles.text}
        >
          {t("missingSomeone.title")}
        </Text>
        <DetailedActionCard
          title={t("missingSomeone.cardTitle")}
          titleColor={ColorStyle.ACTION_CTA}
          subtitle={t("missingSomeone.cardSubtitle")}
          icon="user-plus-01"
          iconColor={ColorStyle.ACTION_CTA}
          onPress={roomId ? openInviteMemberFlow : openInviteCollaborators}
        />
      </View>
      {roomId ? (
        InviteMemberFlow
      ) : (
        <InviteCollaborators
          open={isInviteCollaboratorsOpen}
          onClose={closeInviteCollaborators}
          type="pool"
          poolId={poolId}
          source="room_creation"
        />
      )}
    </>
  );
};

export { MissingSomeone };
