import { createReducer } from "@reduxjs/toolkit";
import { createTransform, persistReducer } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";

import { storage } from "@kraaft/shared/core/modules/storage/storage.provider";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { createAsyncMigrate } from "@kraaft/shared/core/store/storeMigrations";

import * as actions from "./filterActions";
import { FilterState, RoomFilters } from "./filterState";
import { filterMigrations } from "./filterState.migration";

export const DEFAULT_ROOM_FILTERS: RoomFilters = {
  visibility: "member",
  readingStatus: "all",
  statusId: undefined,
  labelIds: [],
  responsibleId: undefined,
  showArchived: false,
  searchText: undefined,
};

export const initialFilterState: FilterState = { pools: {} };

const filterReducers = createReducer(initialFilterState, ({ addCase }) => {
  addCase(UserActions.userDisconnectedFromFirebase, () => initialFilterState);

  addCase(actions.FilterStateActions.setRoomFilters, (state, { payload }) => {
    state.pools[payload.poolId] = {
      ...(state.pools[payload.poolId] ?? DEFAULT_ROOM_FILTERS),
      visibility: payload.visibility,
      statusId: payload.statusId,
      labelIds: payload.labelIds,
      responsibleId: payload.responsibleId,
      showArchived: payload.showArchived,
      readingStatus: payload.readingStatus,
    };
  });

  addCase(
    actions.FilterStateActions.setRoomReconciliatedFilters,
    (state, { payload }) => {
      const roomFilters = state.pools[payload.poolId];

      if (roomFilters) {
        roomFilters.visibility = payload.visibility;
        roomFilters.statusId = payload.statusId;
        roomFilters.labelIds = payload.labelIds;
        roomFilters.responsibleId = payload.responsibleId;
      } else {
        state.pools[payload.poolId] = {
          ...DEFAULT_ROOM_FILTERS,
          visibility: payload.visibility,
          statusId: payload.statusId,
          labelIds: payload.labelIds,
          responsibleId: payload.responsibleId,
        };
      }
    },
  );

  addCase(actions.setRoomFilterSearchText, (state, { payload }) => {
    const roomFilters = state.pools[payload.poolId];

    if (roomFilters) {
      roomFilters.searchText = payload.searchText;
    } else {
      state.pools[payload.poolId] = {
        ...DEFAULT_ROOM_FILTERS,
        searchText: payload.searchText,
      };
    }
  });

  addCase(
    actions.FilterStateActions.setRoomFilterVisibility,
    (state, { payload }) => {
      const roomFilters = state.pools[payload.poolId];

      if (roomFilters) {
        roomFilters.visibility = payload.visibility;
      } else {
        state.pools[payload.poolId] = {
          ...DEFAULT_ROOM_FILTERS,
          visibility: payload.visibility,
        };
      }
    },
  );

  addCase(actions.FilterStateActions.resetFilters, (state, { payload }) => {
    delete state.pools[payload.poolId];
  });
});

const filterWhitelist = createTransform<RoomFilters, Record<string, unknown>>(
  null,
  (outboundState) => {
    return { ...outboundState, searchText: "" } as RoomFilters;
  },
  { blacklist: ["_persist"] },
);

const filterPersistConfig: PersistConfig<FilterState> = {
  key: "filters",
  transforms: [filterWhitelist],
  storage,
  version: 2,
  migrate: createAsyncMigrate(filterMigrations),
};
const persistedReducer = persistReducer(filterPersistConfig, filterReducers);

export { persistedReducer as filterReducers };
