import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { GradientBackground } from "@kraaft/shared/core/modules/surveys/components/answerNpsSurveyForm/answerNpsSurveyFlow/steps/gradientBackground";
import { Color, ColorStyle, PixelSize, Radius, Text } from "@kraaft/ui";

import { HeartsSvg } from "./heartsIcon";

interface ThankingStepProps {
  closeHost: () => void;
  height: number;
}

export const ThankingStep = ({ closeHost, height }: ThankingStepProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      closeHost();
    }, 2500);

    return () => clearTimeout(timer);
  }, [closeHost]);

  return (
    <GradientBackground height={height}>
      {isNative() && (
        <View style={styles.handleContainer}>
          <View style={styles.handle} />
        </View>
      )}
      <View style={styles.container}>
        <HeartsSvg />
        <Text size="H2" weight="bold" color={Color.WHITE}>
          {t("nps.form.thanking.title")}
        </Text>
      </View>
    </GradientBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    minWidth: Platform.select({ web: 450 }),
  },
  handleContainer: {
    position: "absolute",
    width: "100%",
    paddingTop: PixelSize.S8,
    alignItems: "center",
  },
  handle: {
    height: PixelSize.S4,
    width: PixelSize.S48,
    borderRadius: Radius.MAXIMUM,
    backgroundColor: ColorStyle.SEPARATOR,
  },
});
