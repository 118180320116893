import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { AsYouType } from "libphonenumber-js";

import { alphaHex } from "@kraaft/helper-functions";
import { CguLinks } from "@kraaft/shared/components/auth/cguLinks";
import { CountryPicker } from "@kraaft/shared/components/auth/countryPicker";
import { useDeviceDialCode } from "@kraaft/shared/components/auth/countryPicker/countryCodeUtils";
import { FirebaseCaptchaVerifier } from "@kraaft/shared/components/auth/firebaseCaptchaVerifier";
import type { FirebaseCaptchaVerifierHandle } from "@kraaft/shared/components/auth/firebaseCaptchaVerifierHandle";
import { LoginHintPill } from "@kraaft/shared/components/auth/loginHintChips";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { selectTrackDataViewLoginPhone } from "@kraaft/shared/core/modules/app/analyticsSelector";
import { LoginSlice } from "@kraaft/shared/core/modules/login/login.slice";
import { authErrorToTranslation } from "@kraaft/shared/core/services/auth/authErrorToTranslation";
import { useFetchSSOProvider } from "@kraaft/shared/core/services/auth/useSSO";
import { Firebase } from "@kraaft/shared/core/services/firebase";
import { firebaseSDK } from "@kraaft/shared/core/services/firebase/sdk";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useTrackEventOnceWithData } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { useEnsureOnline } from "@kraaft/shared/core/utils/useNetwork";
import { Button, Color, NonAccessibleText, Preloader } from "@kraaft/ui";

import { useSigninTitleForInvitation } from "../useSigninTitleForInvitation";

import { styles } from "./signInPhone.styles";

const SignInPhone = () => {
  const navigationService = useNavigationService();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const captchaHandle = useRef<FirebaseCaptchaVerifierHandle | null>(null);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isWorking, setWorking] = useState(false);

  const trackDataViewLoginPhone = useSelector(selectTrackDataViewLoginPhone);

  const [selectedCode, setSelectedCode] = useDeviceDialCode();

  useEffect(() => {
    handlechangePhoneNumber(phoneNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCode]);

  useTrackEventOnceWithData(trackDataViewLoginPhone);

  const handleSubmitPhoneHint = useCallback(() => {
    const compactPhoneNumber = phoneNumber.replaceAll(/\s/g, "");
    const hint = `******${compactPhoneNumber
      .replaceAll(/\s/g, "")
      .substring(compactPhoneNumber.length - 4)}`;

    dispatch(
      LoginSlice.actions.tryLoginHint({
        hint,
        method: "phone",
      }),
    );
  }, [dispatch, phoneNumber]);

  function handlechangePhoneNumber(number: string) {
    const fullNumber = `${selectedCode}${number}`;

    const asYouType = new AsYouType();
    const formattedNumber = asYouType.input(fullNumber);

    const finalNumber = formattedNumber.replace(selectedCode, "").trim();

    setPhoneNumber(finalNumber);
    setErrorMessage("");
    setError(false);
  }

  const handleCodeChange = useCallback(
    (itemValue: string) => {
      setSelectedCode(itemValue);
      setErrorMessage("");
      setError(false);
    },
    [setSelectedCode],
  );

  const handleCodeTextChange = useCallback(
    (value: string) => {
      handleCodeChange(value);
    },
    [handleCodeChange],
  );

  const fetchSSO = useFetchSSOProvider();

  const handleSubmit = useEnsureOnline(async () => {
    const fullNumberDisplay = `${selectedCode} ${phoneNumber}`;
    const fullNumber = fullNumberDisplay.replaceAll(" ", "");
    setWorking(true);

    try {
      handleSubmitPhoneHint();
      await captchaHandle.current?.verify();

      const { user, provider, linked } = await fetchSSO(fullNumber);

      if (provider && linked) {
        return await firebaseSDK.triggerSSOLogin(provider);
      }

      trackEvent({
        eventName: "Phone Number Sent",
        phone_number: fullNumberDisplay,
      });

      const confirmation = await Firebase.signInWithPhoneNumber({
        phoneNumber: fullNumber,
        applicationVerifier: captchaHandle.current?.getApplicationVerifier(),
      });

      trackEvent({
        eventName: "Phone Number Confirmation Received",
        verificationId: confirmation.verificationId,
      });

      navigationService.setConfirmation(confirmation);

      navigationService.navigate("ConfirmationCode", {
        fullNumber: fullNumberDisplay,
        exists: !!user,
      });
    } catch (err) {
      setError(true);

      if (err.code) {
        const translationKey = authErrorToTranslation(err); // HANDLE FIREBASE ERRORS
        setErrorMessage(t(translationKey, { name: "SSO", error: err.message }));
      } else {
        setErrorMessage(err.message);
      }

      console.log("SignInPhone handleNext error", err);
    } finally {
      setWorking(false);
    }
  }, [
    handleSubmitPhoneHint,
    selectedCode,
    phoneNumber,
    navigationService,
    t,
    fetchSSO,
  ]);

  const handleNavigateToSignInEmail = useCallback(() => {
    navigationService.navigate("SignInEmail");
  }, [navigationService]);

  const title = useSigninTitleForInvitation() ?? t("signIn.enterPhoneNumber");

  return (
    <>
      <View style={styles.container}>
        {isWorking && (
          <Preloader
            absoluteFill
            backgroundColor={alphaHex(Color.WHITE, 0.6)}
          />
        )}
        <NonAccessibleText
          weight="bold"
          size="H1"
          id="ide2e-signinphone-title"
          style={styles.titleContainer}
          numberOfLines={3}
        >
          {title}
        </NonAccessibleText>
        <LoginHintPill method="phone" style={styles.loginHintPillStyle} />
        <View style={styles.inputContainer}>
          <View style={styles.codeInputContainer}>
            <CountryPicker
              selectedCode={selectedCode}
              onChangeCode={handleCodeChange}
              onChangeText={handleCodeTextChange}
              error={error}
            />
          </View>
          <View style={styles.phoneInputContainer}>
            <KInput
              nativeID="ide2e-phone"
              disableAccessibility
              label={t("signIn.phoneNumberLabel")}
              value={phoneNumber}
              onChangeText={handlechangePhoneNumber}
              error={error}
              autoFocus
              keyboardType="number-pad"
              returnKeyType="next"
              clearButtonMode="while-editing"
              onSubmitEditing={handleSubmit}
            />
          </View>
        </View>
        {error && (
          <View style={styles.errorContainer}>
            <NonAccessibleText
              id="ide2e-error"
              size="SMALL"
              color="ACTION_DESCTRUCTIVE"
            >
              {errorMessage || t("internalError")}
            </NonAccessibleText>
          </View>
        )}
        <View style={styles.navigateSpacer} />
        <View style={styles.navigateButtonContainer}>
          <NonAccessibleText
            id="ide2e-login-email"
            weight="light"
            size="BODY"
            color="FONT_LOW_EMPHASIS"
            onPress={handleNavigateToSignInEmail}
          >
            {t("signIn.useMyEmail")}
          </NonAccessibleText>
        </View>
        <View style={styles.buttonSpacer} />
        <Button
          id="ide2e-next"
          disableAccessibility
          onPress={handleSubmit}
          disabled={!phoneNumber}
          text={t("next")}
          style={styles.button}
        />
        <CguLinks />
      </View>
      <FirebaseCaptchaVerifier ref={captchaHandle} />
    </>
  );
};

export { SignInPhone };
