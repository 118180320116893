import { useTranslation } from "react-i18next";
import { Platform, Pressable, StyleSheet, View } from "react-native";

import { useMeshContext } from "@kraaft/helper-hooks";
import ConfettiLottie from "@kraaft/shared/assets/confetti.json";
import { LottieManager } from "@kraaft/shared/components/lottieManager";
import { AnswerNpsSurveyFormContext } from "@kraaft/shared/core/modules/surveys/components/answerNpsSurveyForm/answerNpsSurveyForm.context";
import { Color, Radius, Sheet, Spacing, Text } from "@kraaft/ui";

export const ConfettiSheet = Sheet({ default: "overlay" })
  .create(() => ({ onClose }) => {
    return (
      <LottieManager
        source={ConfettiLottie}
        initialSegment={[0, 200]}
        loop={false}
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "transparent",
        }}
        onComplete={onClose}
      />
    );
  })
  .withDefaults({ withoutBackground: true, withoutPointerEvents: true });

export const NoteStep = () => {
  const { t } = useTranslation();
  const { note, setNote } = useMeshContext(AnswerNpsSurveyFormContext);
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const { open: openConfettiSheet, element: confettiSheet } = ConfettiSheet.use(
    {},
  );

  const handleNoteChanged = (selectedNote: number) => {
    setNote(selectedNote);
    if (selectedNote > 8) {
      openConfettiSheet();
    }
  };

  return (
    <>
      <View style={styles.container}>
        <Text size="BODY" weight="bold" style={styles.stepTitle}>
          {t("nps.form.recommend.title")}
        </Text>
        <View style={styles.noteSelectorContainer}>
          {numbers.map((number) => (
            <Pressable
              style={[
                styles.noteSelector,
                note === number && styles.noteSelectorActive,
              ]}
              onPress={() => handleNoteChanged(number)}
              accessibilityLabel={number.toString()}
              key={`${number}-index`}
            >
              <Text size="SUBTITLE" weight="bold">
                {number}
              </Text>
            </Pressable>
          ))}
        </View>
        <View style={styles.indicationContainer}>
          <Text>{t("nps.form.recommend.unlikely")}</Text>
          <Text>{t("nps.form.recommend.likely")}</Text>
        </View>
      </View>
      {confettiSheet}
    </>
  );
};

export const styles = StyleSheet.create({
  container: {
    minWidth: Platform.select({ web: 450 }),
  },
  stepTitle: {
    marginBottom: Spacing.S12,
  },
  noteSelectorContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: Color.GREY_ANTIFLASH,
    borderRadius: Radius.MEDIUM,
  },
  noteSelector: {
    flex: 1,
    paddingVertical: 20,
    textAlign: "center",
    alignItems: "center",
  },
  noteSelectorActive: {
    backgroundColor: Color.YELLOW_KRAAFT,
    borderRadius: Radius.SMALL,
  },
  indicationContainer: {
    marginTop: Spacing.S4,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
