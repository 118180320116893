import {
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Pressable, StyleSheet } from "react-native";
import { useKeyPressEvent } from "react-use";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { clamp } from "@kraaft/helper-functions";
import { useMeshContextSetup, useUpdate } from "@kraaft/helper-hooks";

import { Color, type IconSize, Spacing } from "../../constants";
import { morphClassNameAsStyle } from "../../utils";
import { stopPropagation } from "../../utils/stopPropagation";
import { Icon } from "../icon";
import { IdentifiableElements } from "./carousel.constants";
import type { CarouselItemRenderer, CarouselProps } from "./carousel.types";
import { CarouselUtils } from "./carousel.utils";
import { CarouselContext } from "./carouselContext";
import { createElementHolder, Page } from "./carouselLayout";

const DELAY_TO_CONSIDER_HOLD_MS = 700;
const ON_HOLD_DELAY_MS = 100;

interface CarouselUiProps {
  onClose: CarouselProps<unknown>["onClose"];
  smallUi: CarouselProps<unknown>["smallUi"];
  hasBefore: boolean;
  hasAfter: boolean;
  handleGoLeft: () => void;
  handleGoRight: () => void;
  titleHost: JSX.Element;
  captionHost: JSX.Element;
  bottomButtonHost: JSX.Element;
}

const CarouselUi = ({
  onClose,
  smallUi,
  hasBefore,
  hasAfter,
  handleGoLeft,
  handleGoRight,
  titleHost,
  captionHost,
  bottomButtonHost,
}: CarouselUiProps) => {
  const classes = useStyles();

  const arrowIconSize: IconSize = smallUi ? "SMALL" : "XLARGE";
  const closeIconSize: IconSize = smallUi ? "SMALL" : "LARGE";

  return (
    <>
      {hasBefore && (
        <Pressable
          style={[
            styles.goleft,
            morphClassNameAsStyle(classes.go),
            { left: smallUi ? Spacing.S4 : Spacing.S32 },
          ]}
          onPress={handleGoLeft}
          accessibilityLabel=""
        >
          <Icon name="chevron-left" size={arrowIconSize} color="WHITE" />
        </Pressable>
      )}
      {hasAfter && (
        <Pressable
          style={[
            styles.goright,
            morphClassNameAsStyle(classes.go),
            { right: smallUi ? Spacing.S4 : Spacing.S32 },
          ]}
          onPress={handleGoRight}
          accessibilityLabel=""
        >
          <Icon name="chevron-right" size={arrowIconSize} color="WHITE" />
        </Pressable>
      )}
      <div className={clsx(IdentifiableElements.FOOTER, classes.footer)}>
        <div onClick={stopPropagation}>{captionHost}</div>
        <div onClick={stopPropagation}>{titleHost}</div>
        <div onClick={stopPropagation}>{bottomButtonHost}</div>
      </div>

      {onClose && (
        <Pressable
          style={[
            styles.close,
            {
              top: smallUi ? Spacing.S4 : Spacing.S32,
              right: smallUi ? Spacing.S4 : Spacing.S32,
            },
          ]}
          onPress={onClose}
          accessibilityLabel=""
        >
          <Icon name="x-close" size={closeIconSize} color="WHITE" />
        </Pressable>
      )}
    </>
  );
};

const Carousel_ = <T,>({
  handle,
  open,
  initialIndex = 0,
  items,
  keyExtractor,
  onClose,
  renderItem,
  onFocusedChange,
  smallUi,
  disableUi,
}: CarouselProps<T>) => {
  const carouselId = `carousel-${useId()}`;

  const classes = useStyles();

  const internRenderItem = useCallback<CarouselItemRenderer<T>>(
    (info) => {
      return renderItem(info);
    },
    [renderItem],
  );

  const [focused, setFocused] = useState(initialIndex);

  useUpdate(() => {
    if (items.length === 0) {
      onClose?.();
    } else if (focused > items.length - 1) {
      setFocused(items.length - 1);
    }
  }, [items.length, focused, setFocused, onClose]);

  useImperativeHandle(handle, () => ({
    goto(index) {
      setFocused(index);
    },
  }));

  useEffect(() => {
    onFocusedChange?.(focused);
  }, [focused, onFocusedChange]);

  const [
    { component: TitleComponent, portalHost: titleHost },
    { component: CaptionComponent, portalHost: captionHost },
    { component: BottomButtonsComponent, portalHost: bottomButtonHost },
    { component: ToolComponent },
  ] = useMemo(
    () =>
      [
        createElementHolder("title", carouselId),
        createElementHolder("caption", carouselId),
        createElementHolder("bottom", carouselId),
        createElementHolder("tool", carouselId),
      ] as const,
    [carouselId],
  );

  const context = useMeshContextSetup<CarouselContext>(
    useMemo(
      () => ({
        focused,
        displayUi: true,
        Page,
        Bottom: BottomButtonsComponent,
        Caption: CaptionComponent,
        Title: TitleComponent,
        Tool: ToolComponent,
      }),
      [
        BottomButtonsComponent,
        CaptionComponent,
        TitleComponent,
        ToolComponent,
        focused,
      ],
    ),
  );

  const handleGoLeft = useCallback(() => {
    setFocused((old) => clamp(old - 1, 0, items.length - 1));
  }, [items.length]);

  const handleGoRight = useCallback(() => {
    setFocused((old) => clamp(old + 1, 0, items.length - 1));
  }, [items.length]);

  const holdInterval = useRef<NodeJS.Timeout | undefined>(undefined);

  useKeyPressEvent("Escape", onClose);
  useKeyPressEvent(
    "ArrowLeft",
    useCallback(() => {
      handleGoLeft();
      holdInterval.current = setTimeout(() => {
        clearTimeout(holdInterval.current);
        holdInterval.current = setInterval(handleGoLeft, ON_HOLD_DELAY_MS);
      }, DELAY_TO_CONSIDER_HOLD_MS);
    }, [handleGoLeft]),
    useCallback(() => {
      clearInterval(holdInterval.current);
    }, []),
  );
  useKeyPressEvent(
    "ArrowRight",
    useCallback(() => {
      handleGoRight();
      holdInterval.current = setTimeout(() => {
        clearTimeout(holdInterval.current);
        holdInterval.current = setInterval(handleGoRight, ON_HOLD_DELAY_MS);
      }, DELAY_TO_CONSIDER_HOLD_MS);
    }, [handleGoRight]),
    useCallback(() => {
      clearInterval(holdInterval.current);
    }, []),
  );

  const didPressOnClosingElement = useRef(false);

  const handleContainerClick = useCallback(() => {
    if (didPressOnClosingElement.current) {
      onClose?.();
    }
  }, [onClose]);

  const hasBefore = focused !== 0;
  const hasAfter = focused < items.length - 1;

  const renderedItems = CarouselUtils.getIndexesToRender(focused);

  return (
    <CarouselContext.Provider value={context}>
      <div
        className={classes.root}
        onClick={handleContainerClick}
        onMouseDown={(event) => {
          const target = event.target;

          if (!(target instanceof HTMLDivElement)) {
            didPressOnClosingElement.current = false;
            return;
          }

          didPressOnClosingElement.current = [
            IdentifiableElements.ITEM,
            IdentifiableElements.FOOTER,
            IdentifiableElements.IMAGE_MANIPULATION,
          ].some((className) => target.classList.contains(className));
        }}
      >
        <div className={classes.displayedContent}>
          <div
            className={classes.allItems}
            style={{
              transform: `translateX(-${focused * 100}%)`,
            }}
          >
            {renderedItems.map((index) => {
              const item = items[index];
              if (!item) {
                return null;
              }
              return (
                <div
                  key={keyExtractor(item)}
                  style={{
                    transform: `translateX(${index * 100}%)`,
                  }}
                  className={clsx(IdentifiableElements.ITEM, classes.item)}
                >
                  {open && internRenderItem({ index, item })}
                </div>
              );
            })}
          </div>
        </div>
        {!disableUi && (
          <CarouselUi
            onClose={onClose}
            smallUi={smallUi}
            hasBefore={hasBefore}
            hasAfter={hasAfter}
            handleGoLeft={handleGoLeft}
            handleGoRight={handleGoRight}
            titleHost={titleHost}
            captionHost={captionHost}
            bottomButtonHost={bottomButtonHost}
          />
        )}
      </div>
    </CarouselContext.Provider>
  );
};

const useStyles = makeStyles({
  root: {
    backgroundColor: Color.GREY_JET,
    flexGrow: 1,
    flexBasis: 0,
    flexShrink: 1,
    display: "flex",
    flexDirection: "column",
  },
  displayedContent: {
    flexGrow: 1,
    flexBasis: 0,
    flexShrink: 1,
    minHeight: 0,
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  allItems: {
    flexBasis: 0,
    flexShrink: 1,
    flexGrow: 1,
    transition: "all 250ms",
    display: "flex",
    flexDirection: "row",
  },
  item: {
    flexBasis: "100%",
    flexShrink: 0,
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  go: {
    transform: "translateY(-50%)",
    userSelect: "none",
  },
});

const styles = StyleSheet.create({
  close: {
    position: "absolute",
  },
  goleft: {
    position: "absolute",
    top: "50%",
  },
  goright: {
    position: "absolute",
    top: "50%",
  },
});

export const Carousel = <T,>({
  open,
  items,
  keyExtractor,
  renderItem,
  handle,
  initialIndex,
  insets,
  onClose,
  onFocusedChange,
  smallUi,
  disableUi,
}: CarouselProps<T>) => {
  if (!open) {
    return null;
  }
  return (
    <Carousel_
      open={open}
      items={items}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      handle={handle}
      initialIndex={initialIndex}
      insets={insets}
      onClose={onClose}
      onFocusedChange={onFocusedChange}
      smallUi={smallUi}
      disableUi={disableUi}
    />
  );
};
