import AsyncStorage from "@react-native-async-storage/async-storage";
import type {
  PersistedState,
  PersistMigrate,
  PersistState,
} from "redux-persist";

import { unserialize } from "./reduxPersistUtils";

async function getAndResetAsyncStoragePreviousValue(storageKey: string) {
  const previousValue = await AsyncStorage.getItem(`persist:${storageKey}`);

  if (previousValue) {
    const previousValueObject = unserialize(previousValue);

    await AsyncStorage.removeItem(`persist:${storageKey}`);

    return previousValueObject;
  }
  return null;
}

export const getAsyncStorageMigrations =
  (key: string): PersistMigrate =>
  async (state, currentVersion): Promise<PersistedState> => {
    if (currentVersion === 0) {
      return getAsyncStorageMigration(key, 0)(state);
    }
  };

export const getAsyncStorageMigration =
  (key: string, version: number) =>
  async <T>(state: T): Promise<T> => {
    let newState: PersistedState = state as PersistedState;
    // Migrate from AsyncStorage to SharedGroupPreferences
    const previousValue = await getAndResetAsyncStoragePreviousValue(key);

    if (previousValue) {
      newState = {
        ...previousValue,
        _persist: { rehydrated: false, version },
      };
    }
    return newState as T;
  };

export interface AsyncMigrationManifest {
  [key: string]: (
    state: Record<string, unknown> | undefined,
  ) => Promise<Record<string, unknown> | undefined>;
}

const DEFAULT_VERSION = -1;

// inspired from redux-persist createMigrate
export function createAsyncMigrate(
  migrations: AsyncMigrationManifest,
  config?: { debug: boolean },
): (state: PersistedState, currentVersion: number) => Promise<PersistedState> {
  const { debug } = config || {};
  // eslint-disable-next-line complexity
  return async (state: PersistedState, currentVersion: number) => {
    if (!state) {
      if (process.env.NODE_ENV !== "production" && debug) {
        console.log("redux-persist: no inbound state, skipping migration");
      }
      return undefined;
    }

    const inboundVersion: number =
      state._persist && state._persist.version !== undefined
        ? state._persist.version
        : DEFAULT_VERSION;
    if (inboundVersion === currentVersion) {
      if (process.env.NODE_ENV !== "production" && debug) {
        console.log("redux-persist: versions match, noop migration");
      }
      return state;
    }
    if (inboundVersion > currentVersion) {
      if (process.env.NODE_ENV !== "production") {
        console.error("redux-persist: downgrading version is not supported");
      }
      return state;
    }

    const migrationKeys = Object.keys(migrations)
      .map((ver) => Number.parseInt(ver, 10))
      .filter((key) => currentVersion >= key && key > inboundVersion)
      .sort((a, b) => a - b);

    if (process.env.NODE_ENV !== "production" && debug) {
      console.log("redux-persist: migrationKeys", migrationKeys);
    }
    try {
      let migratedState: PersistedState = state;
      for (const versionKey of migrationKeys) {
        const migration = migrations[versionKey];
        if (migration) {
          migratedState = (await migration(state)) as PersistedState;
        }
      }
      return migratedState;
    } catch (err) {
      return Promise.reject(err);
    }
  };
}

export type PersistedStateOfType<T extends { [key: string]: any }> = T & {
  _persist: PersistState;
};

export async function migrateFrom<From, To>(
  state: Record<string, unknown> | undefined,
  migrate: (state: From) => To,
) {
  if (!state) {
    return;
  }
  return (await migrate(state as From)) as Record<string, unknown>;
}
