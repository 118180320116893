import { createReducer } from "@reduxjs/toolkit";

import { NpsSurveyStateActions } from "@kraaft/shared/core/modules/surveys/npsSurvey/npsSurvey.actions";
import { NpsSurveyState } from "@kraaft/shared/core/modules/surveys/npsSurvey/npsSurvey.state";

const initialState: NpsSurveyState = {
  npsSurveyResponseId: undefined,
  shouldDisplayNpsSurveyForm: false,
};

export const npsSurveyReducers = createReducer(initialState, ({ addCase }) => {
  addCase(
    NpsSurveyStateActions.setNpsSurveyResponseId,
    (state, { payload: { id } }) => {
      state.npsSurveyResponseId = id;
    },
  );
  addCase(
    NpsSurveyStateActions.setShouldDisplayNpsSurveyForm,
    (state, { payload: { shouldDisplayNpsSurveyForm } }) => {
      state.shouldDisplayNpsSurveyForm = shouldDisplayNpsSurveyForm;
    },
  );
});
