import { forwardRef } from "react";
import { StyleSheet, TextInput, View } from "react-native";

import { ColorStyle, Radius, Spacing } from "../../constants";
import { Icon } from "../../display";

interface ContainedSearchBarProps {
  value: string;
  onChangeText(text: string): void;
  placeholder: string;
  autoFocus?: boolean;
  onFocus?(): void;
}

export const ContainedSearchBar = forwardRef<
  TextInput,
  ContainedSearchBarProps
>(({ value, onChangeText, placeholder, autoFocus, onFocus }, ref) => {
  return (
    <View style={styles.root}>
      <Icon size="MEDIUM" name="search-md" color="FONT_LOW_EMPHASIS" />
      <TextInput
        ref={ref}
        autoFocus={autoFocus}
        style={styles.input}
        placeholder={placeholder}
        placeholderTextColor={ColorStyle.FONT_LOW_EMPHASIS}
        value={value}
        onChangeText={onChangeText}
        onFocus={onFocus}
        accessibilityLabel=""
      />
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    gap: Spacing.S4,
    padding: Spacing.S8,
    borderRadius: Radius.SMALL,
    borderWidth: 1,
    borderColor: ColorStyle.SEPARATOR,
  },
  input: {
    width: "100%",
  },
});
