import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { View, type ViewProps } from "react-native";
import Editor, { type PluginEditorProps } from "@draft-js-plugins/editor";
import createMentionPlugin, {
  type MentionPluginConfig,
} from "@draft-js-plugins/mention";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { ContentState, EditorState } from "draft-js";
import type { MarkRequired } from "ts-essentials";

import { betterForwardRef } from "@kraaft/shared/core/utils/betterForwardRef";
import { FontSize, Portal, Radius } from "@kraaft/ui";

import type { RequiredTextInputComponentProps } from "../../compactTextInput/compactTextInput.types";
import type { Suggestion } from "./types/suggestions";
import { Entry } from "./entry";
import { EntryText } from "./entryText";
import {
  addSuggestion,
  customSuggestionsFilter,
  getFormattedTextWithSuggestions,
  hasUnknownSuggestions,
  updateSuggestionsEntities,
} from "./textInputWithSuggestionUtils";

import "draft-js/dist/Draft.css";

type TextInputWithSuggestionProps = RequiredTextInputComponentProps & {
  placeholder?: string;
  multiline?: boolean;
  wrapperStyle?: ViewProps["style"];
  onFocus?: () => void;
  onBlur?: () => void;
  suggestions: Suggestion[];
  onErrorStateChange?: (error: boolean) => void;
  editorProps?: Omit<PluginEditorProps, "editorState" | "onChange">;
};

type CustomMentionPluginConfig = MarkRequired<
  MentionPluginConfig,
  "entityMutability"
>;

export interface TextInputWithSuggestionHandle {
  addSuggestionInPlace: (suggestion: Suggestion) => void;
}

export const TextInputWithSuggestion = betterForwardRef<
  TextInputWithSuggestionHandle,
  TextInputWithSuggestionProps
>(
  (
    {
      suggestions,
      onErrorStateChange,
      value,
      multiline,
      onChangeText,
      onBlur,
      onFocus,
      editorProps,
      wrapperStyle,
      placeholder,
    },
    ref,
  ) => {
    const classes = useStyles({ singleLine: Boolean(!multiline) });

    const [editorState, setEditorState] = useState(() =>
      EditorState.createWithContent(ContentState.createFromText(value ?? "")),
    );
    const [suggestionSearchValue, setSuggestionSearchValue] = useState("");
    const [open, setOpen] = useState(false);
    const editorRef = useRef<Editor>(null);

    const mentionPluginConfig = useMemo<CustomMentionPluginConfig>(
      () => ({
        entityMutability: "IMMUTABLE",
        supportWhitespace: true,
      }),
      [],
    );

    const { MentionSuggestionsComponent, mentionPlugin } = useMemo(() => {
      const plugin = createMentionPlugin({
        // eslint-disable-next-line react/no-unstable-nested-components
        mentionComponent: ({ mention, ...other }) => (
          <EntryText
            {...other}
            key={(mention as Suggestion).value}
            mention={mention}
            valid={suggestions.some((suggestion) => {
              const entrySuggestion = mention as Suggestion;

              return (
                suggestion.value === entrySuggestion.value &&
                suggestion.name === entrySuggestion.name
              );
            })}
          />
        ),
        ...mentionPluginConfig,
        popperOptions: {
          placement: "bottom-start",
        },
        theme: {
          mentionSuggestionsPopupVisible: clsx(
            "mentionSuggestionsPopupVisible",
            classes.mentionSuggestionsPopupVisible,
          ),
        },
      });
      const { MentionSuggestions } = plugin;
      return {
        mentionPlugin: plugin,
        MentionSuggestionsComponent: MentionSuggestions,
      };
    }, [
      classes.mentionSuggestionsPopupVisible,
      mentionPluginConfig,
      suggestions,
    ]);

    const handleAddSuggestionInPlace = useCallback(
      (suggestion: Suggestion) => {
        const newEditorState = addSuggestion(
          editorState,
          suggestion,
          mentionPluginConfig.mentionPrefix,
          mentionPluginConfig.entityMutability,
        );

        setEditorState(newEditorState);
      },
      [
        editorState,
        mentionPluginConfig.entityMutability,
        mentionPluginConfig.mentionPrefix,
      ],
    );

    useImperativeHandle(ref, () => ({
      addSuggestionInPlace: handleAddSuggestionInPlace,
    }));

    const handleChange = useCallback(
      (_editorState: EditorState) => {
        setEditorState(_editorState);
        const formattedText = getFormattedTextWithSuggestions(_editorState);

        onChangeText?.(formattedText);
      },
      [onChangeText],
    );

    const handleOpenChange = useCallback((_open: boolean) => {
      setOpen(_open);
    }, []);

    const handleSearchChange = useCallback(
      ({ value: newValue }: { value: string }) => {
        setSuggestionSearchValue(newValue);
      },
      [],
    );

    useEffect(() => {
      setEditorState((currentState) =>
        updateSuggestionsEntities(
          currentState,
          suggestions,
          mentionPluginConfig.mentionPrefix,
        ),
      );
    }, [mentionPluginConfig.mentionPrefix, suggestions]);

    useEffect(() => {
      onErrorStateChange?.(hasUnknownSuggestions(editorState, suggestions));
    }, [editorState, onErrorStateChange, suggestions]);

    const displayedSuggestions = useMemo(
      () =>
        customSuggestionsFilter(suggestionSearchValue, suggestions).map(
          (sug) => ({
            id: sug.value,
            ...sug,
          }),
        ),
      [suggestions, suggestionSearchValue],
    );

    const keyFromSuggestion = useMemo(
      () => suggestions.map((s) => `${s.name}-${s.value}`).join("-"),
      [suggestions],
    );

    return (
      <View style={wrapperStyle}>
        <div className={classes.wrapper}>
          <Editor
            key={keyFromSuggestion}
            ref={editorRef}
            editorState={editorState}
            onChange={handleChange}
            plugins={[mentionPlugin]}
            stripPastedStyles
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            {...editorProps}
          />
          <Portal>
            <MentionSuggestionsComponent
              entryComponent={Entry}
              open={open}
              suggestions={displayedSuggestions}
              onOpenChange={handleOpenChange}
              onSearchChange={handleSearchChange}
            />
          </Portal>
        </div>
      </View>
    );
  },
);

export const useStyles = makeStyles({
  mentionSuggestionsPopupVisible: {
    maxHeight: 300,
    borderRadius: Radius.SMALL,
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.11)",
    overflow: "auto",
  },
  wrapper: {
    fontSize: FontSize.MEDIUM,
    "& .DraftEditor-root": {
      width: "100%",
      overflowY: "auto",
    },
    "& .public-DraftEditor-content": {
      minHeight: ({ singleLine }: { singleLine: boolean }) =>
        singleLine ? "0px !important" : 80,
    },
  },

  "@global": {
    ".mentionSuggestionsPopupVisible > :last-child > div > .itemSeparator": {
      display: "none",
    },
  },
});
