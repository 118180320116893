import { ReactNode, useRef } from "react";
import { Pressable, StyleSheet, View } from "react-native";

import { useVoidOnPress } from "@kraaft/helper-hooks";

import { Sheet } from "../../layout";

interface ItemSelectorProps<T> {
  as: ReactNode;
  items: Array<T>;
  renderItem(item: T, close: () => void): ReactNode;
}

interface ItemSelectorSheetProps<T> {
  items: T;
  renderItem(item: T, close: () => void): ReactNode;
}

const ItemSelectorSheet = Sheet({
  web: "anchored",
  native: "bottom",
}).create<ItemSelectorSheetProps<any>>(
  ({ Paper }) =>
    ({ items, renderItem, onClose }) => {
      return (
        <Paper noPadding>
          <View style={styles.item}>
            {items.map((item: any) => renderItem(item, onClose))}
          </View>
        </Paper>
      );
    },
);

const styles = StyleSheet.create({
  item: {
    flexDirection: "row",
  },
});

export const ItemSelector = <T,>({
  as,
  items,
  renderItem,
}: ItemSelectorProps<T>) => {
  const ref = useRef(null);
  const { element, open } = ItemSelectorSheet.use({
    items,
    renderItem,
    anchor: ref,
  });

  const handlePress = useVoidOnPress(open);

  return (
    <>
      <Pressable ref={ref} onPress={handlePress} accessibilityLabel="">
        {as}
      </Pressable>
      {element}
    </>
  );
};
