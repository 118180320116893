import type { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const DEFAULT_ANIMATION_DURATION_MS = 150;

interface HideHorizontalProps {
  hidden: boolean;
  animationDuration?: number;
  className?: string;
}

export const HideHorizontal = ({
  children,
  hidden,
  animationDuration,
  className,
}: PropsWithChildren<HideHorizontalProps>) => {
  const classes = useStyles({ animationDuration });

  return (
    <div
      className={clsx(
        classes.container,
        className,
        hidden && classes.containerHidden,
      )}
    >
      <div className={classes.content}>{children}</div>
    </div>
  );
};

interface MakeStylesProps {
  animationDuration?: number;
}

const useStyles = makeStyles({
  container: {
    display: "grid",
    transition: ({ animationDuration }: MakeStylesProps) =>
      `grid-template-columns ${
        animationDuration ?? DEFAULT_ANIMATION_DURATION_MS
      }ms`,
    gridTemplateColumns: "1fr",
  },
  containerHidden: {
    gridTemplateColumns: "0fr",
  },
  content: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
});
