import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet, TouchableOpacity, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { useHover } from "@kraaft/helper-hooks";
import { AttachmentList } from "@kraaft/shared/components/attachmentList";
import { DroppableContainer } from "@kraaft/shared/components/droppable/droppableContainer";
import { ModularFolderCardProps } from "@kraaft/shared/components/modularFolderCard/types";
import { I18nKeys } from "@kraaft/shared/core/services/i18next/locales/i18n.keys";
import {
  Color,
  ColorStyle,
  DraggableMessageType,
  Icon,
  Image,
  PixelSize,
  Radius,
  Spacing,
  Text,
} from "@kraaft/ui";

import closed from "./__assets__/Closed.png";
import opened from "./__assets__/Opened.png";

// eslint-disable-next-line complexity
export const ModularFolderCardContent = ({
  title,
  description,
  leftView,
  rightIcon,
  hideRightIcon,
  style,
  enableFold,
  enableInteractions,
  attachments,
  attachmentContext,
  onAttachmentClick,
  onClickCard,
  onClickRightIcon,
  onLongPress,
  isActive,
  isDropActive,
  dropAcceptTypes,
  dropRejectTypes,
  canDrop,
  draggedType,
  expanded,
  toggleExpanded,
}: ModularFolderCardProps & {
  expanded: boolean;
  isDropActive?: boolean;
  canDrop?: boolean;
  draggedType?: DraggableMessageType;
  toggleExpanded(): void;
}) => {
  const { t } = useTranslation();

  const containerRef = useRef(null);

  const isHovered = useHover(containerRef);

  const isDraggedTypeRejected = draggedType
    ? !!dropRejectTypes?.includes(draggedType)
    : false;

  const rejectedTranslationKey: I18nKeys | undefined = isDraggedTypeRejected
    ? draggedType === DraggableMessageType.MessageDocument
      ? "depositHereRejected.document"
      : "depositHereRejected.media"
    : undefined;

  const canUnfold = enableFold && !isNative();

  const renderEmpty = useCallback(() => {
    if (!attachments?.length) {
      return (
        <View style={styles.emptyDropContainer}>
          <Text size={16} color="FONT_LOW_EMPHASIS">
            {t(
              rejectedTranslationKey ??
                (!dropAcceptTypes?.length
                  ? "depositHereEmptyNoColumn"
                  : "depositHereEmpty"),
            )}
          </Text>
        </View>
      );
    }
    return null;
  }, [attachments?.length, t, rejectedTranslationKey, dropAcceptTypes]);

  const renderCardFold = useCallback(() => {
    if (canUnfold && (expanded || isDropActive)) {
      return (
        <View style={[styles.documentsContainer, canDrop && styles.canDrop]}>
          {attachmentContext && attachments && attachments.length > 0 && (
            <View style={styles.attachmentsContainer}>
              <AttachmentList
                attachments={attachments}
                context={attachmentContext}
                onAttachmentClick={onAttachmentClick}
              />
            </View>
          )}
          {renderEmpty()}
          {canDrop && (
            <DroppableContainer
              isActive={Boolean(isDropActive)}
              isRejected={isDraggedTypeRejected}
              text={t(rejectedTranslationKey ?? "depositHere")}
            />
          )}
        </View>
      );
    }
    return null;
  }, [
    canUnfold,
    isDraggedTypeRejected,
    rejectedTranslationKey,
    expanded,
    isDropActive,
    canDrop,
    attachmentContext,
    attachments,
    onAttachmentClick,
    renderEmpty,
    t,
  ]);

  const handleLongPress = isNative() ? onLongPress : undefined;

  return (
    <Pressable
      ref={containerRef}
      onPress={onClickCard}
      onLongPress={handleLongPress}
      style={[styles.container, style]}
      accessibilityLabel={title}
    >
      <View
        style={[
          styles.cardContainer,
          (isActive || isHovered) && styles.cardContainerHovered,
        ]}
      >
        {leftView}

        <TouchableOpacity
          accessibilityLabel={title}
          style={[styles.titleAndLogContainer, !leftView && styles.noLeftIcon]}
          onPress={onClickCard}
          onLongPress={handleLongPress}
          disabled={!onClickCard || !enableInteractions}
        >
          <Text
            id="modularFolderCard-title"
            numberOfLines={2}
            style={styles.title}
          >
            {title}
          </Text>
          {description}
        </TouchableOpacity>

        {canUnfold && (
          <Pressable
            accessibilityLabel={expanded ? t("hide") : t("show")}
            onPress={toggleExpanded}
          >
            <Image
              source={expanded ? opened : closed}
              style={styles.folderImage}
            />
          </Pressable>
        )}

        {hideRightIcon ? null : (
          <View nativeID="ide2e-card-details">
            <TouchableOpacity
              accessibilityLabel={rightIcon?.accessibilityLabel}
              style={styles.rightIconButton}
              onPress={onClickRightIcon}
              disabled={!onClickRightIcon || !enableInteractions}
            >
              <Icon
                name={rightIcon?.name ?? "chevron-right"}
                size="MEDIUM"
                color={rightIcon?.color ?? "FONT_LOW_EMPHASIS"}
              />
            </TouchableOpacity>
          </View>
        )}
      </View>

      {renderCardFold()}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    borderRadius: Radius.SMALL,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    overflow: "hidden",
  },
  cardContainer: {
    flexDirection: "row",
    alignItems: "center",
    minHeight: 56,
  },
  cardContainerHovered: {
    backgroundColor: Color.BLUE_LAVENDER,
  },

  noLeftIcon: {
    marginLeft: Spacing.S12,
  },
  titleAndLogContainer: {
    flexDirection: "column",
    flex: 1,
    paddingVertical: Spacing.S8,
    gap: Spacing.S4,
  },
  title: {
    fontSize: 16,
    fontWeight: "500",
    lineHeight: 20,
    letterSpacing: -0.17,
    color: Color.BLACK, // android: force black color when editable=false
  },
  folderImage: {
    width: PixelSize.S24,
    height: PixelSize.S24,
    resizeMode: "contain",
  },
  rightIconButton: {
    paddingLeft: Spacing.S4,
    paddingRight: Spacing.S8,
    justifyContent: "center",
    alignItems: "center",
  },
  attachmentsContainer: {
    margin: 8,
    marginTop: 2,
    paddingLeft: 42,
  },
  emptyDropContainer: {
    backgroundColor: Color.WHITE,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  documentsContainer: {
    position: "relative",
  },
  canDrop: {
    minHeight: 60,
  },
});
