import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import { ScreenshotElement } from "@kraaft/shared/core/utils/screenshotElement/screenshotElement.types";

export const screenshotElement: ScreenshotElement = async (
  element,
  options,
) => {
  const html2canvas = await import("html2canvas");

  const canvas = await html2canvas.default(element, {
    useCORS: true,
    ignoreElements: options?.ignoreElements,
    scale: options?.scale,
  });
  const image = canvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");
  const imageBrowserPath =
    await fileAllocator.temporaryFromExternallyAllocatedDevicePath(image);
  return imageBrowserPath;
};
